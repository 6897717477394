import "plyr-react/plyr.css";
import '../../helpers/styles/customPlyr.css';
import '../../helpers/styles/presentationVideoModal.css';

import React, { useState } from 'react';
import { Nav, NavItem, NavLink, Popover, Button, OverlayTrigger } from 'react-bootstrap';
import PlayIcon from 'remixicon-react/PlayCircleFillIcon';
import { Wrapper } from './styles';

import Section from '../Section/index';
import ProfileImage from '../ProfileImage/index';
import Icon from '../Icon';
import { Address, BasicInformation } from '../../models/user';
import PresentationVideoModal from "../PresentationVideoModal";
import { ButtonCV } from "../ButtonCV";

type SidebarProps = {
  basicInfo: BasicInformation;
  address: Address;
  photo: string;
  presentationVideo: string;
  isCandidate: boolean;
  isExternalLink: boolean;
};

const Sidebar: React.FC<any> = ({
  basicInfo,
  address,
  photo,
  presentationVideo,
  isCandidate = true,
  isExternalLink
}: SidebarProps) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleModal = () => setShowVideoModal(!showVideoModal);

  return (
    <Wrapper className='me-3'>
      <div className='d-flex justify-content-center'>
        {
          !!presentationVideo ? (
            <ProfileImage photo={photo} hasVideo onClick={() => setShowVideoModal(!showVideoModal)}>
              <PlayIcon />
            </ProfileImage>
          ) : <ProfileImage photo={photo} />
        }
        <ButtonCV isExternalLink={isExternalLink}/>
        <PresentationVideoModal presentationVideo={presentationVideo} isOpen={showVideoModal} setShowVideoModal={setShowVideoModal}/>
      </div>
      <Section>
        <h1 className='h5 text-center mb-0'>
          {basicInfo?.useSocialName ? basicInfo?.socialName : basicInfo?.fullName}
        </h1>
        <p className='text-center'>
          {basicInfo?.age && `${basicInfo?.age} anos,`} {basicInfo?.relationship && `${basicInfo?.relationship},`}{' '}
          {basicInfo?.nationality}
          <br />
          {address && `${address?.city} - ${address?.state}`}
        </p>
      </Section>
      <Nav className='flex-column'>
        <NavItem>
          <NavLink href='#sobre-mim'>
            <Icon name='User' className='icon' /> Sobre mim
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#certificacoes'>
            <Icon name='Certificate' className='icon' /> Certificações
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#educacao'>
            <Icon name='Education' className='icon' /> Educação
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#experiencias'>
            <Icon name='Experience' className='icon' /> Experiências
          </NavLink>
        </NavItem>
        {isCandidate ? (
          <NavItem>
            <NavLink href='#atividades-da-vaga'>
              <Icon name='Activity' className='icon' /> Atividades da vaga
            </NavLink>
          </NavItem>
        ) : ''}
      </Nav>
    </Wrapper>
  );
};

export default Sidebar;

import styled from "styled-components";

export const Container = styled.div<{shouldHide: boolean}>`
    display: ${(props) => props.shouldHide ? 'none' : 'block'};
`
export const Button = styled.button`
    color: #FFF;
    font-size: 0.875rem;
    background-color: rgba(81,40,226,1);
    border: 1px solid rgba(81,40,226,1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7rem;
    position: fixed;
    right: 20px;
    bottom: 60px;

    &:hover {
        background-color: #2f1390;
        border-color: #2f1390;
    }

    @media (min-width: 1020px) {
        bottom: 20px;
    }
`

// import axios, { AxiosResponse } from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { Candidate } from '../models/candidate';
import UserContext from './UserContext';
// import { Candidate } from '../models/candidate';

type CandidateContextData = {
    candidate: Candidate;
    // getCandidate({ vacancyId, candidateId, token }: Record<string, string>): Promise<any>;
    setCandidate(candidate: Candidate): void;
}

let CandidateContext = createContext<CandidateContextData>({} as CandidateContextData);

export const CandidateProvider = ({ children }: any): JSX.Element => {
    // const { REACT_APP_API_URL } = process.env;
    const [candidate, setCandidate] = useState<Candidate>({} as Candidate);
    const { setUser } = useContext(UserContext);

    // const getCandidate = async ({ vacancyId, candidateId, token }: any) => {
    //   const response = await 
    //    axios.get(
    //         `${REACT_APP_API_URL}/private/vacancy-candidates/${vacancyId}/${candidateId}`,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         }
    //     );

    //     if(response.status === 200) {
    //         setCandidate(response.data)
    //     }
          
    // }

    const handleCandidate = (candidate: Candidate) => {
        setCandidate(formatCandidate(candidate));
        setUser(candidate.user);
    }

    const formatCandidate = (candidate: Candidate) => {
        
        const isReadingEnable = candidate?.marked?.canReadProfile || true;
        
        if(!isReadingEnable) {
            delete candidate?.user?.basicInformation?.birthday;
            delete candidate?.user?.basicInformation?.nationality;
            delete candidate?.user?.basicInformation?.relationship;
            delete candidate?.user?.basicInformation?.gender;
            delete candidate?.user?.identificationDocuments;
            delete candidate.user?.address;
            delete candidate?.user?.contact;
            delete candidate?.user?.socialNetworks;
        }
        
        if(candidate?.user?.competencies) delete candidate?.user?.competencies.emotionalControl;
        
        return candidate;
    }


    return (
        <CandidateContext.Provider
            value={{
                candidate,
                // getCandidate,
                setCandidate: handleCandidate
            }}
        >
            {children}
        </CandidateContext.Provider>
    )
}

export default CandidateContext;
import React from 'react';
import { ChartRadar, ChartPie, DotsChart } from '../Charts';
import { Content, Section } from './styles';
import { TranslateCompetenciesType, TranslateStamps } from '../../helpers/Translator';
import { Col, Row } from 'react-bootstrap';
import MBTI_DESCRIPTIONS from '../../helpers/MbtiDescriptions';
// import { DotChart } from '../Charts/styles';
type PersonalityProps = {
  personality: any;
};

type SkillsProps = {
  skill: any;
};

type CompetenciesProps = {
  competencie: any;
};

const basePersonality = [
  { key: 'energy', type: 'array' },
  { key: 'information', type: 'array' },
  { key: 'decisions', type: 'array' },
  { key: 'lifestyle', type: 'array' },
];

const baseSkill = [
  { key: 'level1', type: 'array' },
  { key: 'level2', type: 'array' },
  { key: 'level3', type: 'array' },
  { key: 'level4', type: 'array' },
];

const baseCompetencies = [
  { key: 'emotionalControl', type: 'number' },
  { key: 'flexibility', type: 'number' },
  { key: 'focusOnResults', type: 'number' },
  { key: 'iniciative', type: 'number' },
  { key: 'organization', type: 'number' },
  { key: 'personalGuidance', type: 'number' },
];

export const setPersonality = (data: any) => {
  return basePersonality
    .map((personality: any) => ({
      key: personality.key,
      value: Array.isArray(data![personality.key])
        ? data![personality.key].length
          ? data![personality.key]
          : null
        : data![personality.key] || null,
    }))
    .filter((personality) => personality.value);
};

export const setSkills = (data: any) => {
  return baseSkill
    .map((skill: any) => ({
      key: skill.key,
      value: Array.isArray(data![skill.key])
        ? data![skill.key].length
          ? data![skill.key]
          : null
        : data![skill.key] || null,
    }))
    .filter((skill) => skill.value);
};

export const setCompetencies = (data: any) => {
  return baseCompetencies
    .map((compentencie: any) => ({
      key: compentencie.key,
      value: Array.isArray(data![compentencie.key])
        ? data![compentencie.key].length
          ? data![compentencie.key]
          : null
        : data![compentencie.key] || null,
    }))
    .filter((compentencie) => compentencie.value);
};

export const Personality: React.FunctionComponent<PersonalityProps> = ({
  personality,
}: PersonalityProps) => {
  const personalities = setPersonality(personality?.indicators);
  const personalityDescription = MBTI_DESCRIPTIONS[personality.type] as Record<
    string,
    any
  >;
  return (
    <div className='container-fluid'>
      <Content bordered={true}>
        <h1>{personality.type}</h1>
        <h2>
          <span>{personality.personalityType.title}</span>
          <br />
          {personality.personalityType.subtitle}
        </h2>
        <span
          dangerouslySetInnerHTML={{
            __html: personality.personalityType.description,
          }}
        />
      </Content>
      <Content bordered={false}>
        <h2>
          <span>Gráfico da personalidade</span>
        </h2>
        <Row>
          <Col lg={6} className='d-flex align-items-center chart-box'>
            <ChartRadar data={personalities} />
          </Col>
          <Col lg={6}>
            {Object.keys(personalityDescription).map((axis) => {
              return (
                <p key={axis}>
                  <strong className='description_indicator'>
                    <span>{personalityDescription[axis].title}:</span>{' '}
                  </strong>
                  {personalityDescription[axis].description}
                </p>
              );
            })}
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export const English: React.FunctionComponent<SkillsProps> = ({
  skill,
}: SkillsProps) => {
  const skills = setSkills(skill);
  return (
    skill && (
      <Content bordered={false}>
        {skills.map((skill, index) => {
          return (
            <Section key={index} bordered={true}>
              <div className='d-flex align-items-center'>
                <div className='chart-box'>
                  <ChartPie data={skill} />
                </div>
                <div>
                  <h2>
                    <span>{TranslateStamps[skill.key]}</span>
                    <br />
                    {`Nota: ${skill.value * 10}`}
                  </h2>
                </div>
              </div>
            </Section>
          );
        })}
      </Content>
    )
  );
};

export const GeneralKnowledge: React.FunctionComponent<SkillsProps> = ({
  skill,
}: SkillsProps) => {
  const skills = setSkills(skill);

  return (
    skill && (
      <Content bordered={false}>
        {skills.map((skill, index) => {
          return (
            <Section key={index} bordered={true}>
              <div className='d-flex align-items-center'>
                <div className='chart-box'>
                  <ChartPie data={skill} />
                </div>
                <div>
                  <h2>
                    <span>{TranslateStamps[skill.key]}</span>
                    <br />
                    {`Nota: ${skill.value * 10}`}
                  </h2>
                </div>
              </div>
            </Section>
          );
        })}
      </Content>
    )
  );
};

export const Logic: React.FunctionComponent<SkillsProps> = ({
  skill,
}: SkillsProps) => {
  const skills = setSkills(skill);

  return (
    skill && (
      <Content bordered={false}>
        {skills.map((skill, index) => {
          const value = skill.value * 10;
          return (
            <Section key={index} bordered={true}>
              <div className='d-flex align-items-center'>
                <div className='chart-box'>
                  <ChartPie data={skill} />
                </div>
                <div>
                  <h2>
                    <span>{TranslateStamps[skill.key]}</span>
                    <br />
                    {`Nota: ${value % 1 ? value.toFixed(1) : value }`}
                  </h2>
                </div>
              </div>
            </Section>
          );
        })}
      </Content>
    )
  );
};

export const Portuguese: React.FunctionComponent<SkillsProps> = ({
  skill,
}: SkillsProps) => {
  const skills = setSkills(skill);

  return (
    skill && (
      <Content bordered={false}>
        {skills.map((skill, index) => {
          return (
            <Section key={index} bordered={true}>
              <div className='d-flex align-items-center'>
                <div className='chart-box'>
                  <ChartPie data={skill} />
                </div>
                <div>
                  <h2>
                    <span>{TranslateStamps[skill.key]}</span>
                    <br />
                    {`Nota: ${skill.value * 10}`}
                  </h2>
                </div>
              </div>
            </Section>
          );
        })}
      </Content>
    )
  );
};

export const Competencie: React.FunctionComponent<CompetenciesProps> = ({
  competencie,
}: CompetenciesProps) => {
  const competencies = setCompetencies(competencie);

  return (
    competencies && (
      <Content bordered={false}>
        {competencies.map((competencie, index) => (
          <Section key={index} bordered={true} className='comp'>
            <div className='d-flex align-items-center'>
              <div className="chart-box"><DotsChart data={competencie.value} isIcon={false}  /></div>
              <div>
                <h2>
                  { TranslateCompetenciesType[competencie.key] }
                </h2>
              </div>
            </div>
          </Section>
        ))}
      </Content>
    )
  );
};

import React, { useState } from 'react';
import { format } from 'date-fns';
import Section from '../components/Section/index';
import { cellphone, cpf, rg } from '../helpers/Masks';
import ProfileImage from '../components/ProfileImage/index';
import Icon from '../components/Icon';
import PlayIcon from 'remixicon-react/PlayCircleFillIcon';
import { Address, BasicInformation, Contact, Documents, SocialNetwork } from '../models/user';
import PresentationVideoModal from '../components/PresentationVideoModal';
import { ButtonCV } from '../components/ButtonCV';

type Props = {
  aboutMe?: string;
  address?: Address;
  basicInformation: BasicInformation;
  contact?: Contact;
  photo?: string;
  presentationVideo?: string;
  documents?: Documents;
  socialNetwork?: SocialNetwork[];
  isCandidate?: Boolean;
  isExternalLink: boolean;
};

const SocialMediaHelper: Record<string, any> = {
  'Facebook': { icon: <Icon name="Facebook" />, url: 'https://www.facebook.com/' },
  'Instagram': { icon: <Icon name="Instagram" />, url: 'https://www.instagram.com/' },
  'Twitter': { icon: <Icon name="Twitter" />, url: 'https://twitter.com/' },
  'Linkedin': { icon: <Icon name="LinkedIn" />, url: 'https://www.linkedin.com/' },
};

const CISGENDER_LIST = ['Feminino',
'Masculino',
'Mulher Cisgênero',
'Homem Cisgênero'
];

const About: React.FC<Props> = ({
  aboutMe,
  address,
  basicInformation,
  photo,
  presentationVideo,
  contact,
  documents,
  socialNetwork,
  isCandidate = true,
  isExternalLink
}: Props) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleModal = () => setShowVideoModal(!showVideoModal);

  return (
    <Section>
      <div className="d-flex justify-content-center d-lg-none profile-image">
        {
          !!presentationVideo ? (
            <ProfileImage 
            photo={photo} 
            hasVideo 
            onClick={() => setShowVideoModal(!showVideoModal)}
            >
              <PlayIcon />
            </ProfileImage>
          ) : <ProfileImage photo={photo} />
        }
        <ButtonCV isExternalLink={isExternalLink}/>
        {
          !!presentationVideo && 
            <PresentationVideoModal 
              presentationVideo={presentationVideo} 
              isOpen={showVideoModal} 
              setShowVideoModal={setShowVideoModal}
            />
        }
        
      </div>
      <div id='sobre-mim'>
        <h1 className='h5 text-center mb-0 d-lg-none'>
          {basicInformation?.useSocialName 
            ? basicInformation?.socialName
            : basicInformation?.fullName
          }
        </h1>
        <p className='text-center d-lg-none'>
          {basicInformation?.age && `${basicInformation?.age} anos,`}{' '}
          {basicInformation?.relationship && `${basicInformation?.relationship} ,`} {basicInformation?.nationality}
          <br />
          {address && `${address?.city} - ${address?.state}`}
        </p>
        <h2 className='h6'>Sobre mim</h2>
        <section className='mt-4'>
          { (basicInformation?.gender && !CISGENDER_LIST.includes(basicInformation?.gender)) && basicInformation && basicInformation?.fullName && <p className='mb-2'><Icon name='User' color='var(--black)'/> Registro Civil: {basicInformation?.fullName} </p> }
          {basicInformation && basicInformation?.birthday && <p className='mb-2'><Icon name='Calendar' color='var(--black)'/> Nascido em {format(new Date(basicInformation?.birthday), 'dd/MM/yyyy')} {`(${basicInformation?.age} anos)`}</p> }
          {basicInformation && (basicInformation?.gender || basicInformation?.relationship) && <p className='mb-2'><Icon name='Users' color='var(--black)'/> {basicInformation?.gender} {basicInformation?.gender && basicInformation?.relationship ? ', ' + basicInformation?.relationship : basicInformation?.relationship}</p> }
          {contact && contact.email.address && <p className='mb-2'><Icon name='Message' color='var(--black)'/> {contact.email.address}</p> }
          {contact && contact?.cellphone?.number && <p className='mb-2'><Icon name='Cellphone' color='var(--black)'/> {cellphone(contact.cellphone.number)}</p> }
          {documents && <p className='mb-2'>
              <Icon name='ID' color='var(--black)'/> {' '}
              {documents?.rg && `RG: ${rg(documents.rg)}`} {' '}
              {documents?.cpf && `CPF: ${cpf(documents.cpf)}`}
              </p> }
          {address && <p className='mb-2'>
              <Icon name='GPS' color='var(--black)'/>
              {`${address?.street ? address?.street + ',' : ''} ${address?.number ? address?.number + ' -' : ''} ${address?.district ? address?.district + ' -' : ''} ${address?.city && address?.city }, ${ address?.state && address?.state } ${address?.zipCode ? '- CEP: ' + address?.zipCode : ''}`}
            </p>
          }
        </section>
        <p className="mt-4">{aboutMe || 'Não preenchido.'}</p>       
          {
            socialNetwork && socialNetwork.length && socialNetwork
            .map((media, index) => {
              const mediaDetails =  SocialMediaHelper[media.type] as Record<any, any>;
              media.username = media.username.replace('@', '').replace(/(^www.+)/g, 'https://$1');
              const hasLink = media.username.indexOf('http') !== -1;
              return mediaDetails && <a key={index} href={hasLink ? media.username : mediaDetails.url + media.username } target="_blank" rel="noopener noreferrer" style={{margin: '0.25rem'}}>
              {mediaDetails.icon}
            </a>
            })
          }
      
      </div>
    </Section>
  );
};

export default About;

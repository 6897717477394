import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section';
import 'keen-slider/keen-slider.min.css';
import { useParams, useSearchParams } from 'react-router-dom';
import PageModal from '../components/PageModal';
// import CandidateContext from '../contexts/Candidate';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import Loading from '../components/Loader';

import {
  English,
  Personality,
  GeneralKnowledge,
  Logic,
  Portuguese,
  Competencie,
} from '../components/Stamps';
import {
  PersonalityLink,
  EnglishLink,
  GeneralKnowledgeLink,
  LogicLink,
  PortugueseLink,
  CompetenciesLink
} from '../components/Stamps/Cards';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import { TranslateStamps } from '../helpers/Translator';
import Slider from '../components/Slider';
import { User } from '../models/user';
import UserContext from '../contexts/UserContext';

type TaqeStampsLinksProps = {
  candidate: User;
  isCandidate?: Boolean
};

const baseStamps = [
  { key: 'personality', value: 'array' },
  { key: 'english', value: 'array' },
  { key: 'generalKnowledge', value: 'array' },
  { key: 'logic', value: 'array' },
  { key: 'logicAdvanced', value: 'array' },
  { key: 'portuguese', value: 'array' },
  { key: 'competencies', value: 'array' }
];

const setStampsHelper = (data: any) => {
  const setValues = (skill: any) => {
    if (data![skill.key]) {
      return data![skill.key];
    } else {
      if (data!['skills']) {
        const keys = data!['skills'][skill.key];
        if (keys && Object.keys(keys).length) {
          return data!['skills'][skill.key];
        } else {
          return null;
        }
      }
    }
  };

  return baseStamps
    .map((skill: any) => ({
      key: skill.key,
      value: setValues(skill),
    }))
    .filter((skill) => skill.value);
};

export const TaqeStampsLinks: React.FC<TaqeStampsLinksProps> = ({
  candidate,
  isCandidate = true
}: TaqeStampsLinksProps) => {
  // const [searchParams] = useSearchParams();
  // const token = searchParams.get('token');

  const stamps = setStampsHelper(candidate);
  const mounntCard = (stamp: string, data: any, index: number): any => {
    const dynamicCardContent: Record<string, any> = {
      personalityLink: <PersonalityLink data={data} key={index} isCandidate={isCandidate} />,
      englishLink: <EnglishLink data={data} key={index} isCandidate={isCandidate} />,
      generalKnowledgeLink: <GeneralKnowledgeLink data={data} key={index} isCandidate={isCandidate} />,
      logicLink: <LogicLink data={data} key={index} isCandidate={isCandidate} />,
      logicAdvancedLink: <LogicLink data={data} key={index} isCandidate={isCandidate} />,
      portugueseLink: <PortugueseLink data={data} key={index} isCandidate={isCandidate} />,
      competenciesLink: <CompetenciesLink data={data} key={index} isCandidate={isCandidate} />
    };

    return dynamicCardContent[stamp] || <p>Card näo criado</p>;
  };
 
  return (
    <Fragment>
      {!!stamps.length &&
        <Section bordered={true}>
          <div className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'>
            <h2 className='h6 mb-0'>Selos TAQE</h2>
            <p className='mb-0'>
              <small className='message-details'>
                Clique nos cards para ver mais informações
              </small>
            </p>
          </div>
          <Slider>
            {stamps &&
              stamps.map((stamp: any, index: number) => {
                return mounntCard(`${stamp.key}Link`, stamp, index);
              })}
          </Slider>
        </Section>
      }
    </Fragment>
  );
};

export const TaqeStampsPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');
  
  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let activitiesPage: any = {};

  if (!showLoading) {
    const activitiesHelper = setStampsHelper(user);
    menuHelper = setStampsHelper(user).map((stamp) => ({
      ...stamp,
      title: TranslateStamps[stamp.key],
    }));
    activitiesPage = activitiesHelper.find((stamp) => stamp.key === page);
  }

  const mountContent = (page: string): any => {
    const dynamicContent: Record<string, any> = {
      personality: <Personality personality={activitiesPage?.value} />,
      english: <English skill={activitiesPage?.value} />,
      generalKnowledge: <GeneralKnowledge skill={activitiesPage?.value} />,
      logic: <Logic skill={activitiesPage?.value} />,
      logicAdvanced: <Logic skill={activitiesPage?.value} />,
      portuguese: <Portuguese skill={activitiesPage?.value} />,
      competencies: <Competencie competencie={activitiesPage?.value} />
    };

    return dynamicContent[page] || <p>Tela ainda não implementada :/</p>;
  };

  return showLoading ? (<Loading />) : (
    <PageModal
      nav={<DetailsNav navigation={menuHelper} title='Selos TAQE' isCandidate={isCandidate} />}
      badge={TranslateStamps[activitiesPage?.key]}
      content={mountContent(page!)}
    />
  );
};

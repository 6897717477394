import React from 'react';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  PolarRadiusAxis,
  PieChart,
  Pie,
  // Label,
  // Cell,
  LabelList,
  // Text,
  // Sector,
} from 'recharts';
// import { setSkillsHelper } from '../Stamps/Cards';
import { DotChart } from './styles';

type ChartItemProps = {
  key: string;
  value: [{}];
};

type ChartProps = {
  data: ChartItemProps[];
  isIcon?: boolean;
};

type ChartPieProps = {
  data: any;
  isIcon?: boolean;
};

type DotsChartProps = {
  data: any;
  isIcon?: boolean;
};

const setAxes = (indicators: any) => {
  let axes: {}[] = [];

  indicators.map((indicator: any, index: number) => {
    if (indicator.key === 'energy') {
      axes[0] = {
        subject: 'Introversão',
        A: Object.values(indicator.value)[0],
        fullMark: 100,
      };
      axes[4] = {
        subject: 'Extroversão',
        A: Object.values(indicator.value)[1],
        fullMark: 100,
      };
    }

    if (indicator.key === 'information') {
      axes[2] = {
        subject: 'Sensação',
        A: Object.values(indicator.value)[0],
        fullMark: 100,
      };
      axes[6] = {
        subject: 'Intuição',
        A: Object.values(indicator.value)[1],
        fullMark: 100,
      };
    }

    if (indicator.key === 'decisions') {
      axes[3] = {
        subject: 'Pensamento',
        A: Object.values(indicator.value)[0],
        fullMark: 100,
      };
      axes[7] = {
        subject: 'Sentimento',
        A: Object.values(indicator.value)[1],
        fullMark: 100,
      };
    }

    if (indicator.key === 'lifestyle') {
      axes[5] = {
        subject: 'Julgamento',
        A: Object.values(indicator.value)[0],
        fullMark: 100,
      };
      axes[1] = {
        subject: 'Percepção',
        A: Object.values(indicator.value)[1],
        fullMark: 100,
      };
    }

    return indicator;
  });

  return axes;
};

export const ChartRadar: React.FunctionComponent<ChartProps> = ({
  data,
  isIcon,
}: ChartProps) => {
  const axes = setAxes(data);

  return (
    <ResponsiveContainer width={'100%'} height={300} minHeight={'auto'}>
      <RadarChart
        data={axes}
        cx='50%'
        cy='50%'
        outerRadius='70%'
        style={{ display: 'block' }}
      >
        <defs>
          <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor='#5663FF' />
            <stop offset='50%' stopColor='#5128E2' />
            <stop offset='100%' stopColor='#4625D8' />
          </linearGradient>
        </defs>
        <PolarGrid strokeDasharray={'3 3'} />
        <PolarAngleAxis
          dataKey='subject'
          tick={isIcon ? false : true}
          tickLine={isIcon ? false : true}
          strokeDasharray={'0 0'}
        />
        <PolarRadiusAxis tick={false} tickLine={false} strokeOpacity={0} />
        <Radar dataKey='A' fill='url(#colorUv)' fillOpacity={0.8} offset={8} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const setPieData = (data: any) => {
  let array = [{ name: '', angle: 0, nota: 0 }];
  array = [
    {
      name: data.key,
      angle: (data.value * 100 * 360) / 100,
      nota: Number((data.value * 100).toFixed(1)),
    },
  ];
  return array;
};

export const ChartPie: React.FunctionComponent<ChartPieProps> = ({
  data,
  isIcon,
}: ChartPieProps) => {
  const setPies = setPieData(data);
  return (
    <ResponsiveContainer>
      <PieChart data={setPies} cx='100%' cy='100%'>
        <Pie
          data={setPies}
          dataKey='nota'
          startAngle={360}
          endAngle={0}
          innerRadius={'68%'}
          fill='#FFFFFF'
          label={false}
          strokeWidth={0}
          isAnimationActive={false}
        />
        <Pie
          data={setPies}
          dataKey='nota'
          startAngle={setPies[0].angle}
          endAngle={0}
          innerRadius={'68%'}
          paddingAngle={0}
          fill='#5128E2'
          label={false}
          strokeWidth={0}
          cornerRadius={40}
        >
          <LabelList position={'center'} angle={0} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export const avarageHelper = (data: any) => {
  // let notes: number[] = [];
  // const grades = setSkills(data);

  // grades.map(grade => {
  //   notes.push(grade.value);
  //   return grade;
  // });
  
  delete data.totalAverage;
  const notes: number[] = Object.values(data);

  const avarage = (notes.reduce((a, b) => a + b, 0)/notes.length) * 100;

  return Number(avarage.toFixed(1));
}

const setPieDataIcon = (data: any) => {
  const avarage = avarageHelper(data);

  let array = [{ name: '', angle: 0, nota: 0 }];
  array = [
    {
      name: 'Média',
      angle: (avarage * 360) / 100,
      nota: avarage,
    },
  ];
  return array;
};

export const ChartPieIcon: React.FunctionComponent<ChartPieProps> = ({
  data,
  isIcon,
}: ChartPieProps) => {
  const setPies = setPieDataIcon(data);

  return (
    <ResponsiveContainer>
      <PieChart data={setPies} cx='100%' cy='100%'>
        <Pie
          data={setPies}
          dataKey='nota'
          startAngle={360}
          endAngle={0}
          innerRadius={'68%'}
          fill='#FFFFFF'
          label={false}
          strokeWidth={0}
          isAnimationActive={false}
        />
        <Pie
          data={setPies}
          dataKey='nota'
          startAngle={setPies[0].angle}
          endAngle={0}
          innerRadius={'68%'}
          paddingAngle={0}
          fill='#5128E2'
          label={false}
          strokeWidth={0}
          cornerRadius={40}
        >
          <LabelList position={'center'} angle={0} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export const DotsChart: React.FunctionComponent<DotsChartProps> = ({
  data,
  isIcon = false,
}: DotsChartProps) => {
  return (
    <DotChart setIcon={isIcon}>
      {competenceDots(data)}
    </DotChart>
  );
};

function competenceDots(competencePoints: number = 0): JSX.Element {
  const none = '';
  const full = 'full';

  const cssColorSet: Record<string, string[]> = {
    'none': [none, none, none],
    'small': [full, none, none],
    'medium': [full, full, none],
    'large': [full, full, full],
  };
  let eixo = 'none';
  if (competencePoints > 0 && competencePoints < 8) {
    eixo = 'small';
  }
  if (competencePoints >= 8 && competencePoints < 15) {
    eixo = 'medium';
  }
  if (competencePoints >= 15) {
    eixo = 'large';
  }

  return <>{ (cssColorSet[eixo] || []).map(cssClass => <span className={cssClass}/>) }</>;
}
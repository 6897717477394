import styled from "styled-components";

export const Container = styled.div<{shouldHide: boolean}>`
  display: ${(props) => props.shouldHide ? 'none' : 'block'};
  .popover-sharing{
    background: var(--primary-color);
    width: 35px;
    height: 35px;
    border-radius: 50%; 
    border: none;
    margin-left: 20px;
    margin-top: -80px;

    position: relative;
    z-index: 1;
  }
`;
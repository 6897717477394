import { Stamp } from '../../components/Stamp';
import { TaqeStamps } from '../../components/TaqeStamps';

import { ExperienceProps, Experiencia } from "../../components/Experience";
import { Knowledge, KnowledgeProps } from "../../components/Knowledge";

import education from '../../../../../assets/education.svg';
import experience from '../../../../../assets/experience.png';
import graduations from '../../../../../assets/graduation.png';
import hand from '../../../../../assets/hand.png';
import stampsBlue from '../../../../../assets/stamps_blue.svg';

import { Certificates } from "../../components/Certificates";
import { Education, EducationProps } from '../../components/Education';
import { UserProps } from "../../interface/IUser";
import { Container, ContentCertificates } from './styles';

export function PageOfBrowser({ user }: UserProps) {
  const hasAboutMe = user.aboutMe && user.aboutMe.trim().length > 0;
  const hasTaqeCertificates = user.skills && Object.keys(user.skills).length !== 0;
  const hasCertificates = user.certificates && user.certificates.length > 0;
  const hasEducation = user.education && user.education.length > 0;
  const hasVolunteering = user.volunteering && user.volunteering.length !== 0;

  return (
    <Container>
      {hasAboutMe && (
        <ContentCertificates>
          <div className="title">
            <Stamp img={graduations} alt="Selo Sobre mim" />
            <h6>SOBRE MIM</h6> 
          </div>
          <div className='about-text'>
            <span>
              {user.aboutMe}
            </span>
          </div>
        </ContentCertificates>
      )}

      {hasTaqeCertificates && (
        <ContentCertificates>
          <div className='title'>
            <Stamp img={stampsBlue} alt="Selo certificados TAQE" />
            <h6>CERTIFICADOS TAQE</h6>
          </div>
          <div className='content'>
            <TaqeStamps user={user} />
          </div>
        </ContentCertificates>
      )}

      {hasCertificates && (
        <ContentCertificates noBorder={true}>
          <div className="title">
            <Stamp img={education} alt="Selo certificados Educacionais" />
            <h6>CERTIFICADOS EDUCACIONAIS</h6>
          </div>
          <div className='content'>
            <Certificates user={user} />
          </div>
        </ContentCertificates>
      )}

      {hasEducation && (
        <ContentCertificates>
          <div className="title">
            <Stamp img={graduations} alt="Selo Educação" />
            <h6>Educação</h6>
          </div>
          <div className="content-grid">
            {user.education.map((edu: EducationProps, index: number) => (
              <Education
                key={index}
                name={edu.name}
                formation={edu.formation}
                in={edu.in}
                until={edu.until}
              />
            ))}
          </div>
        </ContentCertificates>
      )}

      {user.professionalExperience && user.professionalExperience.length > 0 && (
          <ContentCertificates>
            <div className="title">
              <Stamp img={experience} alt="Selo Expêriencia" />
              <h6>Expêrencia</h6>
            </div>
            {user.professionalExperience.map((exp: ExperienceProps, index: number) => (
              <Experiencia
                key={index}
                organization={exp.organization}
                office={exp.office}
                city={exp.city}
                description={exp.description}
                in={exp.in}
                until={exp.until}
              />
            ))}
          </ContentCertificates>
        )}

      {hasVolunteering && (
        <ContentCertificates noBorder={true}>
          <div className="title">
            <Stamp img={hand} alt="Selo certificados de Vivência" />
            <h6>Vivência</h6>
          </div>
          {user.volunteering.map((item: KnowledgeProps, index: number) => (
            <Knowledge
              key={index}
              organization={item.organization}
              cause={item.cause}
              description={item.description}
              in={item.in}
              until={item.until}
            />
          ))}
        </ContentCertificates>
      )}
    </Container>
  );
}

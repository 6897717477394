import styled from "styled-components";

export const StampLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  gap:0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #CCEEFF;
    
    img {
      width: 15px;
      height: 15px;
    }
`;
import React from 'react';
import { List } from './styles';
import { Curriculums } from '../../models/user';

type ProfessionalExperienceProps = {
  curriculums: Curriculums[];
};

export const Curriculum: React.FC<ProfessionalExperienceProps> = ({
  curriculums,
}: ProfessionalExperienceProps) => {
  return (
    <List>
      {
        curriculums && curriculums.map((curriculum, index) => {
          return (
            <li key={`${curriculum.title + index}`}>
              <p>{curriculum.title}</p>
              {curriculum.description && <p>{curriculum.description}</p> }
              <a href={curriculum.file.url} rel="noreferrer" target="_blank">
                Abrir arquivo em uma nova aba
              </a>
            </li>
          );
        }
        )
      }
      {
        curriculums && !curriculums.length && <p>Não possui currículos</p>
      }
    </List>
  );
};

import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;

  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background: var(--primary-color);
  color: white;

  padding: 1rem 1rem;
  word-break: break-word;

  margin-right: 5rem !important;
  border-radius: 0px 50px 50px 0px;

  h5 {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
  }

  h6 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const Section = styled.section`
  padding: 1rem;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;

  h5 {
    margin-top: 1rem;
  }
`;

export const Stamp = styled.div`
  margin-top: -70px;
  margin-left: 120px;
    img {
      width: 25px;
      height: 25px;
    }
`

export const ContactContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-top: 1rem;

    position: relative;

    span {
      font-size: 0.76rem;
    }
  }
`;

export const IconContainer = styled.div`
  position: relative;
`;


export const SectionCompoetence = styled.div`
  margin-bottom: 2rem;
`;

export const HeaderSectionConpetence = styled.div`
  .title-competence {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-top: 1rem;
    gap: 0.5rem;

    strong {
      text-align: start;
      font-weight: 400;
    }

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      background: #cceeff;
      color: var(--primary-color);
      font-weight: bold;

      width: 30px;
      height: 30px;

      border-radius: 50%;
    }
  }
`;

export const ContentQRCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1rem;

  span {
    margin-top: 0.5rem;
    text-align: center;
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding: 20px;

  margin-top: 1.5rem;
`;

export const LogoContainer = styled.div`
  margin-top: 3rem;
  text-align: center;

  img {
    width: 45px;
    height: 45px;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

export const ContentContainer = styled.div`
  margin-top: 1rem;
`;



import styled from "styled-components";

export const CardWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  text-align: center;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 8px;

  text-transform: uppercase;
  color: var(--primary-color);

  max-width: 300px;
  max-height: 160px;

  padding: 8px 16px;

  margin: 5px;

  box-sizing: content-box;
`;
import { StampLayout } from "./styles";

interface StampProps {
  img: string;
  alt: string;
}

export function Stamp({ img, alt }: StampProps) {
  return(
    <StampLayout>
      <img src={img} alt={alt} />
    </StampLayout>
  )
}
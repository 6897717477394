// import axios from 'axios';
import React, { createContext, useState } from 'react';
import { SkillI, User } from '../models/user';

type UserContextData = {
    user: User;
    setUser(user: User): void;
}

let UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider = ({ children }: any): JSX.Element => {
    // const { REACT_APP_API_URL } = process.env;
    const [user, setUser] = useState<User>({} as User);

    // const geUser = async ({ username, nameTag }: any) => {
    //     const response = await axios.get(`${REACT_APP_API_URL}/public/professional-identity/${username}/${nameTag}`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     });

    //     if (response.status === 200) {
    //         setUser(response.data)
    //     }
    // }

    const handleUser = (user: User) => {
        setUser(formatUser(user));
    }

    const formatUser = (user: User) => {
        // const isReadingEnable = user?.marked?.canReadProfile || false;

        // if(!isReadingEnable) {
        //     delete candidate?.user?.basicInformation?.birthday;
        //     delete candidate?.user?.basicInformation?.nationality;
        //     delete candidate?.user?.basicInformation?.relationship;
        //     delete candidate?.user?.basicInformation?.gender;
        //     delete candidate?.user?.identificationDocuments;
        //     delete candidate.user?.address;
        //     delete candidate?.user?.contact;
        //     delete candidate?.user?.socialNetworks;
        //     if(candidate?.user?.competencies) delete candidate?.user?.competencies.emotionalControl;
        // }
        user.skills = user.skills ? setSkills(user.skills!) : {};

        return user;
    }

    const setSkills = (skills: SkillI) => {
        return Object.keys(skills).reduce((acc, cur) => {
            if(cur === 'logic') {
                const level4 = {
                    ...(skills[cur]!['level4'] ? {level4: skills[cur]!['level4']} : {})
                };
                const basicLevels =  { ...skills[cur] };
                delete basicLevels.level4;

                return {
                    ...acc,
                    ...(Object.keys(basicLevels).length ? { logic: basicLevels }: {}),
                    logicAdvanced: level4 ,
                }
            }
            return {
                ...acc,
                [cur]: skills[cur]
            };
        }, {});
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser: handleUser
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;

import styled from 'styled-components';

interface ICardInfo {
  full?: boolean;
  outline?: boolean;
}

export const CardInfo = styled.div<ICardInfo>`
  align-items: ${(props) =>
    props.full || props.outline ? 'flex-start' : 'center'};
  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: ${(props) =>
    props.full || props.outline ? 'row' : 'column'};
  cursor: pointer;
  overflow: visible;
  margin-top: ${(props) => props.full || props.outline ? '0' : '2.969rem'};
  margin-bottom: 1.25rem;
  padding: ${(props) =>
    props.full || props.outline
      ? '1rem 1.25rem 1rem 1.25rem'
      : '0 0.625rem 2.5rem 0.625rem'};
  position: relative;
  text-align: ${(props) => (props.full || props.outline ? 'left' : 'center')};
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: rgba(248, 248, 248, 1);
  }

  @media (max-width: 1020px) {
    flex-direction: column;
  }

  .card-info-header {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: ${props => props.outline ? '0' : '0.875rem'};

    @media (min-width: 1020px) {
      margin-bottom: 0;

      ${props => props.outline ? 'max-width: 100%; min-width: 100%;' : 'max-width: 13.25rem; min-width: 13.25rem;'};
    }
  }
`;

export const CardInfoIcon = styled.span`
  align-items: center;
  background-color: rgba(231, 240, 255, 1);
  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 100%;
  display: inline-flex;
  height: 5.938rem;
  justify-content: center;
  margin-top: -2.969rem;
  margin-bottom: 1.063rem;
  overflow: hidden;
  position: relative;
  width: 5.938rem;
  z-index: 1;

  img {
    max-height: 100%;
  }
`;

export const CardInfoTitle = styled.h1<ICardInfo>`
  color: rgba(81, 40, 226, 1);
  font-size: 1rem;
  line-height: 1.375rem;
  ${(props) => props.full ? `
  overflow: hidden;
  text-overflow: ellipsis;
  `: ''}
`;

export const CardInfoSubtitle = styled.p<ICardInfo>`
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
  ${(props) => (props.full || !props.outline ? 'font-weight: 400' : 'font-weight: 600')};
  ${(props) => (props.outline && 'font-weight: 400')};
  line-height: 1.375rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardInfoExcerpt = styled.p<ICardInfo>`
  font-size: 1rem;
  line-height: 1.375rem;
  margin: ${(props) =>
    props.full || props.outline ? '0 0 0 0' : '1.313rem 0 0 0'};
  ${(props) => props.full ? `
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`: ''}
`;

import { User } from "../../../../../models/user";
import Card from "../Card";
import CardContent from "../Card/CardContent";
import CardHeader from "../Card/CardHeader";
import Text from "../Text";

interface CertificatesProps {
  user: User;
}

export function Certificates({ user }: CertificatesProps) {
  return (
    <>
      {user.certificates && user.certificates.map((certificate) => {
        const { certification } = certificate;
        const logo = certification.logo ? certification.logo : certificate.company.logo;
        return (
          <Card key={certificate._id}>
            <CardHeader>
              <img src={logo} />
            </CardHeader>
            <CardContent>
              <Text variant="title">{certification.name}</Text>
              <Text variant="subtitle">{certificate.company.fantasyName}</Text>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}

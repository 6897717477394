import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 1.875rem;
  margin-top: 8.9375rem;
  padding: 2rem 0.75rem;
  position: fixed;

  .nav {
    &-item {
      &:last-of-type {
        border-bottom: 1px solid rgba(108, 123, 138, 0.08);
      }
      border-top: 1px solid rgba(108, 123, 138, 0.08);
    }
    &-link {
      align-items: center;
      color: #5128e2;
      display: flex;
      font-size: 1rem;
      padding: 1.25rem 4.25rem 1.25rem 3.125rem;

      .icon {
        font-size: 1rem;
        margin-right: 1.125rem;
      }
    }
  }
  
  @media (max-width: 1400px) {
    width: 20rem;
    margin-top: 7.9375rem;

    section {
      padding: 1rem;
      p {
        margin: 0;
      }
    }

    .nav {
      &-item {
        height: auto
      }

      &-link {
        padding-left: 1.25rem 3.75rem 1.25rem 0;
      }
    }

  }
`;
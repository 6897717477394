import {
  Navbar as Bar,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'react-bootstrap';
import Icon from '../Icon';

const Navbar = () => {
  return (
    <Bar fixed='bottom' className='d-lg-none bg-white' id='mobile-nav'>
      <Container>
        <Nav fill className='w-100'>
          <NavItem>
            <NavLink href='#sobre-mim'>
              <Icon name='User' className='icon' size={'1.375rem'} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href='#certificacoes'>
              <Icon name='Certificate' size={'1.375rem'} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href='#educacao'>
              <Icon name='Education' size={'1.375rem'} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href='#experiencias'>
              <Icon name='Experience' size={'1.375rem'} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href='#atividades-da-vaga'>
              <Icon name='Activity' size={'1.375rem'} />
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </Bar>
  );
};

export default Navbar;


import { LanguageLevel } from "../../..";
import logo from "../../../../../assets/logo-green.svg";
import ProfileImage from "../../../../../components/ProfileImage";
import QRcode from "../../../../../components/Qrcode";
import GetEnvironment from "../../../../../helpers/GetEnvironment";
import { Competence } from "../Competence";

import { UserProps } from "../../interface/IUser";
import {
    ContentContainer,
    ContentQRCode,
    HeaderContainer,
    HeaderSectionConpetence,
    LogoContainer,
    ProfileContainer,
    QRCodeContainer,
    Section,
    SectionCompoetence,
    Stamp,
    TitleContainer,
} from "./styles";

import { Contact } from "../Contact";

import stamp from '../../../../../assets/stamps.svg';


interface HeaderProps extends UserProps{
  className?: string;
}

export function Header({ user, className }: HeaderProps) {
  const { softwares, languages, username } = user;

  const urlCVQrCode = new URL(`${GetEnvironment().ip}/${username}/details`);

  const visibleTechnology = softwares?.slice(0, 3);
  const remainingTechnology =
    softwares?.length && softwares.length > 3 ? softwares.length - 3 : 0;

  const visebleLanguage = languages?.slice(0, 3);
  const remainingLanguage =
    languages?.length && languages.length > 3 ? languages.length - 3 : 0;

  function toLanguageLevel(level: string): LanguageLevel {
    switch (level) {
      case "Básico":
        return "Básico";
      case "Intermediário":
        return "Intermediário";
      case "Avançado":
        return "Avançado";
      default:
        throw new Error(`Invalid language level: ${level}`);
    }
  }

  return (
    <HeaderContainer>
      <Section>
        <ProfileContainer>
          <ProfileImage photo={user.photo} hasBorder={true} size="medium" />
          <Stamp>
            <img src={stamp} alt="Selo" />
          </Stamp>
        </ProfileContainer>
        <TitleContainer>
          <h5>
            {user.basicInformation?.useSocialName
              ? user.basicInformation?.socialName
              : user.basicInformation?.fullName}
          </h5>
          <span>
            {user.basicInformation?.age} Anos, {user.address?.city},{" "}
            {user.address?.state}
          </span>
        </TitleContainer>

       <Contact user={user} />

        {user.languages || user.softwares ? (
          <SectionCompoetence>
            <ContentContainer>

              <h5>CONHECIMENTOS</h5>
        
              <HeaderSectionConpetence>
                <div className="title-competence">
                  <strong>IDIOMAS</strong>
                  {remainingLanguage > 0 && (
                    <div className="circle">
                      <span>+ {remainingLanguage}</span>
                    </div>
                  )}
                </div>
                {visebleLanguage?.map((item) => [
                  <Competence key={item._id} name={item.name} level={toLanguageLevel(item.level)} />,
                ])}
              </HeaderSectionConpetence>
            </ContentContainer>

            <ContentContainer>
              <HeaderSectionConpetence>
                <div className="title-competence">
                  <strong>TECNOLOGIAS</strong>
                  {remainingTechnology > 0 && (
                    <div className="circle">
                      <span>+{remainingTechnology}</span>
                    </div>
                  )}
                </div>
                {visibleTechnology?.map((item) => [
                  <Competence key={item._id} name={item.name} level={toLanguageLevel(item.level)} />,
                ])}
              </HeaderSectionConpetence>
            </ContentContainer>
          </SectionCompoetence>
        ) : null}

        <ContentQRCode>
          <QRCodeContainer>
            <QRcode size={86} destination={urlCVQrCode} />
          </QRCodeContainer>
          <span>Escaneie o QR code para analisar as informações mais detalhadas</span>
        </ContentQRCode>

        <LogoContainer>
          <img src={logo} alt="Logo TAQE" />
        </LogoContainer>
      </Section>
    </HeaderContainer>
  );
}

import { cellphone } from "../../../../../helpers/Masks";
import { UserProps } from "../../interface/IUser";
import { ContactContainer, IconContainer } from "./styles";

import message from '../../../../../assets/envelope-thin.svg';
import cellPhone from '../../../../../assets/phone-volume-light.svg';

export function Contact({ user }: UserProps) {
  return (
    <>
      {user.contact && (
        <ContactContainer>
        <h5>Contato:</h5>
        <IconContainer>
          <img src={cellPhone} alt="" color="white"/>
          <span>{cellphone(user.contact && user.contact?.cellphone?.number)}</span>
        </IconContainer>
      
        <IconContainer>
          <img src={message} alt="" color="white"/>
          <span>{user.contact && user.contact.email.address}</span>
        </IconContainer>
      </ContactContainer>
      )}
    </>
  )
}
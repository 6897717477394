import React from 'react';
import { Button, Container } from './style';
import { RWebShare } from "react-web-share";

export type WebShareProps = {
  title: string;
  text: string;
  url: string;
  buttonAriaLabel: string;
  isExternalLink: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const WebShare: React.FunctionComponent<WebShareProps> = ({ title, text, url, children, buttonAriaLabel, isExternalLink, onClick }) => {
  return (
    <Container shouldHide={isExternalLink}>
      <RWebShare
        data={{
          text,
          url,
          title,
        }}
        onClick={onClick}
      >
        <Button aria-label={buttonAriaLabel}>{children}</Button>
      </RWebShare>
    </Container>
  );
};

export default WebShare

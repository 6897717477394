import styled from "styled-components";

export const ExperienceContent = styled.div`
    .header{
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: var(--primary-color);
      margin-bottom: 0.5rem ;

      .circle{
        width: 11px;
        height: 11px;
        border-radius: 50%;
  
        background-color: var(--primary-color);
  
        margin: 12px 0px;
      }
    }
    .content{
      display: flex !important;
      flex-direction: column;
      border-left: 1px solid #E5E5E8;

      margin-bottom: 0.5rem;

      padding-top: 0px;

      margin-left: 5px;
      
      span {
        font-weight: 400;
        color: #68656E;
        font-size: 12px;
        word-wrap: break-word;
      }
      
      strong{
        text-transform: uppercase;
        font-weight: 700;
        color: #68656E;
        line-height: 1px;
        word-wrap: break-word;
        font-size: 12px;
      }
    }
`;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section/index';
import Card from '../components/Card';
import { GetYear } from '../helpers/Dates';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import CandidateContext from '../contexts/Candidate';
import UserContext from '../contexts/UserContext';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import PageModal from '../components/PageModal';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import { LifeExperiece } from '../components/LifeExperience';
import Loading from '../components/Loader';

type LifeExperiencesItemsProp = {
  organization: string;
  office: string;
  cause: string;
  in: string;
  until: string;
  description: string;
  colaborateHere: boolean;
  _id: string;
};

type LifeExperiencesProp = {
  experiences: LifeExperiencesItemsProp[];
  isCandidate?: Boolean;
};

export const LifeExperiencesLinks: React.FC<LifeExperiencesProp> = ({
  experiences,
  isCandidate = true
}: LifeExperiencesProp) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      breakpoints: {
        '(min-width: 420px)': {
          slides: { perView: 2, spacing: 11 },
        },
        '(min-width: 760px)': {
          slides: { perView: 3, spacing: 11 },
        },
      },
      slides: {
        perView: 2,
        spacing: 11,
      },
    },
    []
  );

  return (
    <Fragment>
      <Section bordered={true}>
        <div className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4' id='vivencias'>
          <h2 className='h6 mb-0'>Vivências</h2>
          <p className='mb-0'>
            <small className='message-details'>
              Clique nos cards para ver mais informações
            </small>
          </p>
        </div>

        {experiences && !!experiences.length && (
          <div className='keen-slider' ref={sliderRef}>
            {experiences.map((experience, index) => (
              <Link
                to={`vivencias?page=${experience._id}${isCandidate ? `&token=${token}` : '' }`}
                className='keen-slider__slide'
                key={index}
              >
                <Card
                  title={experience.office}
                  subtitle={experience.cause}
                  details={formatDate(
                    experience.in,
                    experience?.until,
                    experience.colaborateHere
                  )}
                  outline={true}
                  full={true}
                />
              </Link>
            ))}
          </div>
        )}

        {experiences && !experiences.length && <p>Não possui vivências.</p>}
        {!experiences && <p>Não preenchido.</p>}
      </Section>
    </Fragment>
  );
};

const formatDate = function (
  initial: string,
  until: string,
  atTheMoment: boolean
): JSX.Element {
  return (
    <>
      {GetYear(initial)} -{' '}
      {atTheMoment ? (
        <span style={{ fontWeight: 600, color: 'rgba(51, 226, 180, 1)' }}>
          Atualmente
        </span>
      ) : (
        GetYear(until)
      )}
    </>
  );
};

export const LifeExpiriencesPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');
  
  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let expiriencesPage: any = {};

  if (!showLoading) {
    const experiences = user.volunteering;

    menuHelper = experiences.map((experience: any) => ({
      ...experience,
      title: experience.organization,
      key: experience._id
    }));
  
    expiriencesPage = experiences.find((experience: any) => experience._id === page);
  }

  return showLoading ? (<Loading />) : (<PageModal nav={<DetailsNav navigation={menuHelper} title="Vivências" isCandidate={isCandidate} />} content={<LifeExperiece experience={expiriencesPage} />} badge={expiriencesPage?.organization} />);
};

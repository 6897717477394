import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import {
    // Navbar as Bar,
    Nav,
    NavItem,
} from 'react-bootstrap';
import { MenuImage, Wrapper, WrapperLink } from './styles';
import { Link, useLocation, useSearchParams, LinkProps, useResolvedPath } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useContext } from 'react';
import UserContext from '../../contexts/UserContext';

type DetailsNavProps = {
    navigation: any[];
    title: string;
    isCandidate?: Boolean
}

export const DetailsNav: React.FC<DetailsNavProps> = ({ navigation, title, isCandidate = true }: DetailsNavProps) => {
    const { user } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const token = searchParams.get('token');

    let hash = location.pathname.split('/').splice(-1).join().toString();
    let path = location.pathname.replace(`/${hash}`, '');

    return (
        <Wrapper className='col-md-3'>
            <section className='navbar'>
                <HashLink className='close_link' to={{
                    pathname: path,
                    search: isCandidate ? `?token=${token}` : '',
                    hash: hash !== path ? `#${hash}` : ''
                }}>
                    <CloseFillIcon size={'1.375rem'} />
                </HashLink>

                {user?.photo && (
                    <section className='d-none d-xl-block section_img'>
                        <MenuImage photo={user?.photo} />
                    </section>
                )}

                <h1>{title}</h1>
            </section>
            <Nav className='d-none d-md-block d-flex flex-column justify-content-end'
            >
                {
                    navigation.map(nav => (
                        <NavItem key={nav.key}>
                            <CustomLink
                                to={`?page=${nav.key}${isCandidate ? `&token=${token}` : '' }`}
                            >{nav.title}</CustomLink>
                        </NavItem>
                    ))
                }
            </Nav>

        </Wrapper>
    );
};

export default DetailsNav;

const CustomLink = ({ to, children }: LinkProps) => {
    let resolver = useResolvedPath(to);
    const location = useLocation();
    let match = (resolver.pathname + resolver.search) === (location.pathname + location.search);
    return <WrapperLink>
            <Link to={to} style={{ fontWeight: match ? "700" : "unset", color: match ? 'var(--black)' : "unset" }}>
                {children}
            </Link>
        </WrapperLink>;
}
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section/index';
import Card from '../components/Card';
import { GetYear } from '../helpers/Dates';
import { TranslateLevelEducation } from '../helpers/Translator';
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import CandidateContext from '../contexts/Candidate';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import PageModal from '../components/PageModal';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import { Education } from '../components/Education';
import UserContext from '../contexts/UserContext';
import Loading from '../components/Loader';

export type EducationItemProps = {
  type: string;
  institution: string;
  formation: string;
  typeCourses: [];
  state: string;
  in: Date;
  until: Date;
  studyHere: boolean;
  _id: string;
  untilYear: string;
  name: string;
};

type EducationLinksProps = {
  educations: EducationItemProps[];
  levelEducation: string;
  isCandidate?: Boolean
};

export const EducationLinks: React.FC<EducationLinksProps> = ({
  educations,
  levelEducation,
  isCandidate = true
}: EducationLinksProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  return (
    <Fragment>
      <Section bordered={true}>
        <div
          className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'
          id='educacao'
        >
          <h2 className='h6 mb-0'>
            Educação
            <br />
            <small>
              <span>{TranslateLevelEducation(levelEducation)}</span>
            </small>
          </h2>
          <p className='mb-0'>
            <small className='message-details'>
              Clique nos cards para ver mais informações
            </small>
          </p>
        </div>

        {educations && !!educations.length && educations.map((education: any) => (
          <Link
            to={`educacao?page=${education._id}${isCandidate ? `&token=${token}` : '' }`}
            className='education-item'
            key={education._id}
          >
            <Card
              full={true}
              title={education.name}
              description={
                <Fragment>
                  {education.standardInstitution
                    ? <>{education.standardInstitution.name}<br /></>
                    : education.institution ? <>{education.institution}<br /></> : <></> }
                  {education.levelType ? education.levelType : education.name}
                  <br />
                  {GetYear(education.in)} -{' '}
                  {education.studyHere ? (
                    <span
                      style={{
                        fontWeight: 600,
                        color: 'rgba(51, 226, 180, 1)',
                      }}
                    >
                      Cursando
                    </span>
                  ) : (
                    GetYear(education.until)
                  )}
                  <br />
                  {education.description}
                </Fragment>
              }
              subtitle={education.formation}
            />
          </Link>
        ))}
        {educations && !educations.length && <p>Não tem escolaridade.</p>}
        {!educations && <p>Não preenchido.</p>}
      </Section>
    </Fragment>
  );
};

export const EducationPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');
  
  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let educationsPage: any = {};

  if (!showLoading) {
    const educations = user.education;
    
    menuHelper = educations.map((education: any) => ({
      ...education,
      title: education.name,
      key: education._id
    }));

    educationsPage = educations.find((education: any) => education._id === page);
  }

  return showLoading ? (<Loading />) : (<PageModal nav={<DetailsNav navigation={menuHelper} title="Educação" isCandidate={isCandidate} />} content={<Education education={educationsPage} />} badge={educationsPage?.name} />);
};

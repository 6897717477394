import { useContext, useRef, useState } from "react";
import UserContext from "../../contexts/UserContext";
import { useReactToPrint } from "react-to-print";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { DisplayLayout } from "../../pages/LayoutIP/styles";
import LayoutIP from "../../pages/LayoutIP";
import './popover.scss';
import { Container } from "./styles";

type ButtonCvProps = {
  isExternalLink: boolean;
};

export function ButtonCV({ isExternalLink }: ButtonCvProps) {
  const { user } = useContext(UserContext);
  const componentRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false)
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'layout-cv',
  });

  const handlePrintWithWarning = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
    const isOpera = (!!window as any).opr || (!!window as any).opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isEdge = navigator.userAgent.indexOf('Edge') > -1;
    const isIE = (window as any).document.documentMode !== undefined; // Internet Explorer 11
    const isIE10 = !!(window as any).MSInputMethodContext && (document as any).documentMode; // Internet Explorer 10
    
    if (isSafari || isFirefox || isOpera || isEdge || isIE || isIE10) {
      alert('Esta função ainda está sendo aprimorada neste navegador. Por favor, utilize o Google Chrome para salvar em PDF ou imprimir seu CV.');
      setShow(false);  
      return;
    }
    handlePrint();
    
  };
  
  const popover = (
    <Popover id="popover" className="position-absolute">
      <Popover.Content as="div" className="popover-content">
        <button onClick={handlePrintWithWarning} className="button-print">
        <FontAwesomeIcon icon={regular("arrow-down-to-bracket")} />
          Salvar em PDF
        </button>
      </Popover.Content>
    </Popover>
  );
  
  return (
    <Container shouldHide={isExternalLink}>
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
        <Button className="popover-sharing">
          <FontAwesomeIcon icon={regular("ellipsis-vertical")} />
        </Button>
      </OverlayTrigger>
      <DisplayLayout>
        <LayoutIP 
          user={user}  
          forwardedRef={componentRef}
        />
      </DisplayLayout>
    </Container>
  );
}

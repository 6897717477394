import { ExperienceContent } from "./styles";

export interface ExperienceProps {
  organization: string;
  office: string;
  city: string;
  description: string;
  in: string;
  until: string;
}

export function Experiencia({ organization, office, city, description, in: start, until: end }: ExperienceProps) {
  const startDate = new Date(start);
  const endDate = end ? new Date(end) : undefined;

  const formattedStartDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`;
  const formattedEndDate = endDate ? `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}` : 'Trabalhando atualmente';

  return (
    <ExperienceContent>
      <div className="header">
        <div className="circle"></div>
        <span><strong>{organization}</strong> {formattedStartDate} - {formattedEndDate}</span>
      </div>
      <div className="content">
        <section>
          <strong className="office">{office}</strong>
        </section>
        <span>
          {city} <br />
          {description}
        </span>
      </div>
    </ExperienceContent>
  );
}

import React from 'react';
import { GetYear } from '../../helpers/Dates';
import { TranslateEducationType } from '../../helpers/Translator';
import { Section } from './styles';

type EducationPage = {
  education: any;
};

export const Education: React.FunctionComponent<EducationPage> = ({
  education,
}: EducationPage) => {
  return (
    <Section bordered={true}>
      <div className='d-flex align-items-center'>
        <div className='infos'>
          <h1>
            {education.name}
          </h1>
          <p className='mb-0'>
            {education.institution ? education.institution : <></>}
            {education.standardInstitution && education.standardInstitution.name}
            <br />
            {education.state}
          </p>
        </div>
      </div>

      <div className='infos'>
        <h2>{education.formation}</h2>
        <p className='mb-0'>{education.levelType || null}</p>
        <p className='mb-0'>
          {TranslateEducationType[education.typeCourse[0]]}
        </p>
        <p>
          {GetYear(education.in)} -{' '}
          {education.studyHere ? (
            <span
              style={{
                fontWeight: 600,
                color: 'rgba(51, 226, 180, 1)',
              }}
            >
              Cursando
            </span>
          ) : (
            GetYear(education.until)
          )}
        </p>
      </div>
    </Section>
  );
};

import styled from "styled-components";

export const ArrowStyle = styled.div`
    svg {
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        fill: var(--primary-color);
        cursor: pointer;
        margin: 0 5px;

        &.arrow--left {
            left: 5px;
        }

        &.arrow--right {
            left: auto;
            right: 5px;
        }

        &.arrow--disabled {
            fill: rgba(185, 185, 185, 0.5);
        }
    }
`;
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section/index';
import { User } from '../models/user';
import { TranslateKnowledges } from '../helpers/Translator';
import { useKeenSlider } from 'keen-slider/react';
import Badge from '../components/Badge';
import PageModal from '../components/PageModal';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import { Languages, Softwares } from '../components/Knowledges';
import 'keen-slider/keen-slider.min.css';
import Icon from '../components/Icon';
import UserContext from '../contexts/UserContext';
import Loading from '../components/Loader';

type KnowledgesLinksProps = {
  candidate: User;
  isCandidate?: Boolean
};

const baseKnowledges = [
  { key: 'languages', type: 'array' },
  { key: 'softwares', type: 'array' },
];

const setKnowledgeHelper = (candidate: User): false | Record<any, any>[] => {
  return candidate && baseKnowledges
    .map((knowledge: any) => ({
      key: knowledge.key,
      value: Array.isArray(candidate![knowledge.key])
        ? candidate![knowledge.key].length
          ? candidate![knowledge.key]
          : null
        : candidate![knowledge.key] || null,
    })).filter((knowledge) => knowledge.value);
};

export const KnowledgesLinks: React.FC<KnowledgesLinksProps> = ({
  candidate,
  isCandidate = true
}: KnowledgesLinksProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      breakpoints: {
        '(min-width: 420px)': {
          slides: { perView: 2, spacing: 10 },
        },
        '(min-width: 760px)': {
          slides: { perView: 5, spacing: 14 },
        },
      },
      slides: {
        perView: 2,
        spacing: 10,
      },
    },
    []
  );

  const knowledgeList = setKnowledgeHelper(candidate);
  return (
    <Fragment>
      <Section bordered={true}>
        <div
          className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'
          id='conhecimentos'
        >
          <h2 className='h6 mb-0'>Conhecimentos</h2>
          <p className='mb-0'>
            <small className="message-details">Clique nos cards para ver mais informações</small>
          </p>
        </div>

        <div className='keen-slider' ref={sliderRef}>
          {knowledgeList && !!knowledgeList.length && knowledgeList.map((knowledge: any) => {
            return (
              <Link
                to={`conhecimentos?page=${knowledge.key}${isCandidate ? `&token=${token}` : '' }`}
                className='keen-slider__slide'
                key={knowledge.key}
              >
                <Badge
                  icon={knowledge.key === 'softwares' ? <Icon name="Computer" className='icon' color='var(--black)' size={'2rem'} /> : <Icon name="Language" className='icon' color='var(--black)' size={'2rem'} />}
                  category={TranslateKnowledges[knowledge.key]}
                  title={knowledge.value[0].name}
                  description={knowledge.value[0].level}
                />
              </Link>
            );
          })}
          {knowledgeList && !knowledgeList.length && <p>Não preenchido</p>}
        </div>
      </Section>
    </Fragment>
  );
};

export const KnowledgesPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');
  
  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let activitiesPage: any = {};

  if (!showLoading) {
    const activitiesHelper = setKnowledgeHelper(user) || [];
    menuHelper = (setKnowledgeHelper(user) || []).map(knowledge => ({ ...knowledge, title: TranslateKnowledges[knowledge.key] }));
    activitiesPage = activitiesHelper.find(knowledge => knowledge.key === page);
  }

  const mountContent = (page: string): any => {
    const dynamicContent: Record<string, any> = {
      languages: <Languages languages={activitiesPage?.value} />,
      softwares: <Softwares softwares={activitiesPage?.value} />
    }

    return dynamicContent[page] || (<p>Tela ainda não implementada :/</p>);
  }

  return showLoading ? (<Loading />) : (<PageModal nav={<DetailsNav navigation={menuHelper} title="Conhecimentos" isCandidate={isCandidate} />} content={mountContent(page!)} badge={TranslateKnowledges[activitiesPage?.key]} />);
};

// import { CommonButtonProps } from "react-bootstrap/esm/Button";
import styled from "styled-components";
import defaultPic from '../../assets/default-picture.png';

export const Wrapper = styled.div` color: #5128E2;
display: flex;
flex-direction: column;
align-items: end;


.navbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;

    .section_img {
        width: 100%;
        align-self: flex-start;
    }

    h1 {
        align-self: flex-end;
    }

    .close_link {
        align-self: flex-end;
    }
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: row-reverse;
        display: flex;
        padding: 0.5rem;

        h1 {
            border-bottom: none;
        }

        .close_link {
            margin: 0;
        }
    }
}

@media (min-width: 768px) {

    position: fixed;
}

h1 {
    font-size: 1.25rem;
    width: 90%;
    border-bottom: 1px solid rgba(70, 37, 216, 0.2);
    margin: 0
}

.close_link {
    margin: 0.75rem;
    color: var(--primary-color);
}

.nav {
    width: 80%;
    justify-content: center;

    &-item {
        border-bottom: 1px solid rgba(70, 37, 216, 0.2);
        height: 2.75rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        a.active {
            color: red;
        }

    }

    a {
        color: #5128E2;
    }

}
`

interface ICircleImage {
    photo: any;
}

export const MenuImage = styled.div<ICircleImage>`
    background-image: url(${props => props.photo ? props.photo : defaultPic});
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    height: 4.75rem;
    width: 4.75rem;
    align-self: flex-start;
    margin-left: -2.375rem;
`

export const WrapperLink = styled.div`
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
`
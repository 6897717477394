import React from 'react';
import { CircleContent, Circle } from './styles';

interface CompetenceProps {
  name: string;
  level: 'Básico' | 'Intermediário' | 'Avançado';
}

export const Competence: React.FC<CompetenceProps> = ({ name, level }) => {
  return (
    <CircleContent>
      <Circle active={level === 'Básico' || level === 'Intermediário' || level === 'Avançado'} />
      <Circle active={level === 'Intermediário' || level === 'Avançado'} />
      <Circle active={level === 'Avançado'} />

      <div>
       <strong>{name}</strong>
      </div>
    </CircleContent>
  );
};

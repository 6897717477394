import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 2;
  box-sizing: content-box; 
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 79px;
  width: 79px;

  position: relative;

  background-color: rgba(231, 240, 255, 1);
  border: 1px solid rgba(223, 223, 223, 1);

  margin: 16px 0px;

  border-radius: 50%;

  img {
    width: 95px;
    height: 95px;

    max-width: 100%;
    max-height: 100%;
    
    border-radius: 50%;
  }
`;
import { User } from '../../models/user';
import { Header } from './LayoutOfBrowser/components/Header';
import { PageOfBrowser } from './LayoutOfBrowser/pages/PageOfBrowser';
import { HeaderOfSafari } from './LayoutOfSafari/components/HeaderOfSafari';
import { PageOfSafari } from './LayoutOfSafari/pages/PageOfSafari';

import './styles.css';

type LayoutCVProps = {
  forwardedRef: React.ForwardedRef<HTMLDivElement>;
  user: User;
} & React.HTMLProps<HTMLDivElement>;

export type LanguageLevel = 'Básico' | 'Intermediário' | 'Avançado';

const LayoutIP: React.FC<LayoutCVProps> = ({
  forwardedRef,
  user,
  ...rest
}) => { 

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      {isSafari ? 
        <div ref={forwardedRef} {...rest} className="LayoutOfSafari" >
          <HeaderOfSafari user={user} />
          <div className="content-of-safari">
            <PageOfSafari user={user} />
          </div>
        </div>
        : 
        <div ref={forwardedRef} {...rest} className="LayoutIP">
          <Header className='header' user={user} />
          <PageOfBrowser user={user} />
        </div>
      }
    </>
  );
}

export default LayoutIP;

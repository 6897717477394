/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Section from '../Section';
import { Wrapper } from './styles';

interface IFooter {
    paddingBottom: any;
}

const Footer: React.FC<IFooter> = ({paddingBottom}: IFooter) => {
  return (
    <Wrapper paddingBottom={paddingBottom}>
      <Section bordered={true}>
        <p className='mb-0 text-center'>
          Desenvolvido por{' '}
          <a href='' className='brand'>
            <img src='https://www.taqe.com.br/wp-content/themes/taqe/assets/images/logo-roxo.svg' />
          </a>
        </p>
      </Section>
    </Wrapper>
  );
};

export default Footer;

import React from 'react';
import { Section } from './styles';

type CertificateProps = {
  certificate: any;
};

export const Certificate: React.FunctionComponent<CertificateProps> = ({
  certificate,
}: any) => {
  const certification: any = certificate.certification || {};
  const logo = certification.logo ? certification.logo : certificate.company.logo;
  return (
    <Section bordered={true}>
      <div className='d-flex align-items-center mb'>
        <div className='logo'>
          <img src={logo} alt={certification.name} />
        </div>

        <div className='infos'>
          <h1>{certification.name}</h1>
          <p>
            Oferecido por {certificate.company.fantasyName}
            <br />
            {certification.workload} Horas
          </p>
        </div>
      </div>

      <p>{certification.description}</p>
    </Section>
  );
};

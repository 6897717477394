const baseStamps = [
  { key: 'personality', value: 'array' },
  { key: 'english', value: 'array' },
  { key: 'generalKnowledge', value: 'array' },
  { key: 'logic', value: 'array' },
  { key: 'logicAdvanced', value: 'array' },
  { key: 'portuguese', value: 'array' },
  { key: 'competencies', value: 'array' }
];

const serializeStamps = (data: any) => {
  const setValues = (skill: any) => {
    if (data![skill.key]) {
      return data![skill.key];
    } else {
      if (data!['skills']) {
        const keys = data!['skills'][skill.key];
        if (keys && Object.keys(keys).length) {
          return data!['skills'][skill.key];
        } else {
          return null;
        }
      }
    }
  };

  return baseStamps
    .map((skill: any) => ({
      key: skill.key,
      value: setValues(skill),
    }))
    .filter((skill) => skill.value);
};

export {
  serializeStamps,
}
import React, { ReactNode } from "react";
import { CircleImage } from "./styles";
import { MediumCircleImage } from "./styles";
import { SmallCircleImage } from "./styles";
import * as S from "./styles";
import { Container } from "react-bootstrap/lib/Tab";

type ProfileImageProps = {
  photo: any;
  hasBorder?: boolean;
  size?: "small" | "medium" | "default";
  isFloating?: boolean;
  hasVideo?: boolean;
  children?: ReactNode;
  onClick?: () => void;
};

const ProfileImage: React.FC<ProfileImageProps> = ({ photo, hasBorder = false, size = "default", isFloating = true, hasVideo = false, children, ...rest }: ProfileImageProps) => {
  let CircleImageComponent = CircleImage;

  if (size === "medium") {
    CircleImageComponent = MediumCircleImage;
  } else if (size === "small") {
    CircleImageComponent = SmallCircleImage;
  }

  return (
    <>
      {!hasVideo ? (
        <CircleImageComponent
          photo={photo}
          hasBorder={hasBorder}
          isFloating={isFloating}
        />
      ) : (
        <S.VideoAvatar photo={photo} {...rest}>
          {children}
        </S.VideoAvatar>
      )}
    </>
  );
};

export default ProfileImage;

import React from "react";
// import { format, formatDistance } from "date-fns";
// import { List, TTWrapper } from "./styles";
// import GetEnvironment from "../../helpers/GetEnvironment";
// import { useParams, useSearchParams } from "react-router-dom";
// import CandidateContext from "../../contexts/Candidate";
// import { secondsToMinutes } from "../../helpers/Time";
import {
  CommonDisabilitiesDetails,
  Disabilities,
  MedicalReport,
} from "../../models/user";
import { HEARING_LOSS } from "../../helpers/Translator";

const CommonDisabilitiesDetailsView: React.FunctionComponent<
  CommonDisabilitiesDetails
> = ({
  description,
  needSpecialResource,
  trainingCourses,
  useAnyDevice,
  usePublicTransport,
}: CommonDisabilitiesDetails) => {
  return (
    <section>
      <p className="m-0 fw-bold text-dark">Descrição</p>
      <p className="m-0">{description}</p>
      <p className="m-0 fw-bold text-dark">
        Precisa de algum recurso de acessibilidade? (libras, software de voz,
        outros).
      </p>
      <p className="m-0">{useAnyDevice?.option ? "Sim" : "Não"}</p>
      {useAnyDevice?.option && (
        <p className="m-0">{useAnyDevice?.description}</p>
      )}
      <p className="m-0 fw-bold text-dark">
        Necessita de algum recurso especial?
      </p>
      <p className="m-0">{needSpecialResource?.option ? "Sim" : "Não"}</p>
      {needSpecialResource?.option && (
        <p className="m-0">{needSpecialResource?.description}</p>
      )}
      <p className="m-0 fw-bold text-dark">Você utiliza transporte público?</p>
      <p className="m-0">{usePublicTransport ? "Sim" : "Não"}</p>
      <p className="m-0 fw-bold text-dark">
        Cursos de capacitação que tenha realizado
      </p>
      <p className="m-0">{trainingCourses}</p>
    </section>
  );
};

type MedicalReportsViewProps = {
  reports: MedicalReport[];
};

const MedicalReportsView: React.FunctionComponent<MedicalReportsViewProps> = ({
  reports,
}: MedicalReportsViewProps) => {
  return (
    <>
      <p className="m-0 pt-4 fw-bold text-dark">Laudos</p>
      {reports &&
        reports.map((report) => (
          <section key={report.type}>
            <p className="m-0 fw-bold text-dark">CID</p>
            <p className="m-0">{report.type}</p>
            <p className="m-0 fw-bold text-dark">Arquivo</p>
            <p className="m-0">
              <a
                href={report.file?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {report.file?.name}
              </a>
            </p>
          </section>
        ))}
    </>
  );
};

type DisabilitiesProps = {
  disabilities: Disabilities;
};

const VisuallyImpaired: React.FunctionComponent<DisabilitiesProps> = ({
  disabilities,
}: DisabilitiesProps) => {
  return (
    <>
      <CommonDisabilitiesDetailsView {...disabilities.details} />
      {disabilities.details?.readBraille && (
        <TextArea
          title="Realiza leitura em braile?"
          details={disabilities.details.readBraille ? "Sim" : "Não"}
        ></TextArea>
      )}
      <MedicalReportsView reports={disabilities.medicalReports || []} />
    </>
  );
};

const HearingImpaired: React.FunctionComponent<DisabilitiesProps> = ({
  disabilities,
}: DisabilitiesProps) => {
  return (
    <>
      <CommonDisabilitiesDetailsView {...disabilities.details} />
      {disabilities.details?.hearingLossLevel && (
        <TextArea
          title="Nível de perda auditiva"
          details={HEARING_LOSS[disabilities.details?.hearingLossLevel]}
        ></TextArea>
      )}
      {disabilities.details?.needsAnResponsible && (
        <TextArea
          title="Precisa de acompanhante/responsável?"
          details={disabilities.details.needsAnResponsible ? "Sim" : "Não"}
        ></TextArea>
      )}
      {disabilities.details?.useLibras && (
        <TextArea
          title="Utiliza a Língua Brasileira de Sinais (Libras)?"
          details={disabilities.details.useLibras ? "Sim" : "Não"}
        ></TextArea>
      )}
    {disabilities.details?.doLipReading && (
        <TextArea
          title="Interpreta leitura labial?"
          details={disabilities.details.doLipReading ? "Sim" : "Não"}
        ></TextArea>
      )}
      <MedicalReportsView reports={disabilities.medicalReports || []} />
    </>
  );
};

const GeneralDisability: React.FunctionComponent<DisabilitiesProps> = ({
  disabilities,
}: DisabilitiesProps) => {
  return (
    <>
      <CommonDisabilitiesDetailsView {...disabilities.details} />
      <MedicalReportsView reports={disabilities.medicalReports || []} />
    </>
  );
};

type TextAreaProps = {
  title: string;
  details: string;
};

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  details,
  title,
}: TextAreaProps) => {
  return (
    <>
      <p className="m-0 fw-bold text-dark">{title}</p>
      <p className="m-0">{details}</p>
    </>
  );
};

export { VisuallyImpaired, GeneralDisability, HearingImpaired };

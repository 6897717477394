import React from 'react';
import { Container } from './style';

export type ButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
}

export const Button: React.FunctionComponent<ButtonProps> = ({ children, onClick }) => {
  return <Container onClick={onClick}>{children}</Container>;
};

import styled from 'styled-components';

interface CircleProps {
  active: boolean;
}

export const CircleContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  div{
    strong{
      font-size: 0.85rem;
      margin-left: 0.5rem;
    }
  }

`;

export const Circle = styled.div<CircleProps>`
  width: 14px;
  height: 14px;

  border: 1px solid #fff;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#fff' : 'transparent')};
  margin-right: 0.3rem;
`;

import { QRCodeSVG } from 'qrcode.react';
import { IQrcode } from '../../models/qrcode';

export default function QRcode({ destination, size = 80 }: IQrcode) {
  return (
    <QRCodeSVG 
      value={destination.toString()}
      size={size}
      level='L'
      bgColor={'white'}
      fgColor={'black'}
    />
  )
}
import { Routes, Route, useNavigate } from 'react-router-dom';

import { Error as ErrorPage } from '../pages/Error';
import { Button } from './Button';
import { UserPage } from '../pages/UserPage';
import { CandidatePage } from '../pages/CandidatePage';
import { PublicCandidatePage } from '../pages/PublicCandidatePage';
import { CertificatesPage } from '../pages/Certificates';
import { KnowledgesPage } from '../pages/Knowledges';
import { VacancyActivitiesPage } from '../pages/VacancyActivities';
import { TaqeStampsPage } from '../pages/TaqeStamps';
import { EducationPage } from '../pages/Educations';
import { ProfessionalExperiencesPage } from '../pages/ProfessionalExpiriences';
import { LifeExpiriencesPage } from '../pages/LifeExpiriences';
import { PWDPage } from '../pages/PersonWithDisability';
import { CurriculumsPage } from '../pages/Curriculums';

const userRoutes = (
  <Route path='/'>
    <Route
      path=':username/:nameTag/:protected?'
      element={<UserPage />}
    />
    <Route
      path=':username/:nameTag/:protected?/selos'
      element={<TaqeStampsPage isCandidate={false} />}
    />
    <Route
      path=':username/:nameTag/:protected?/certificacoes'
      element={<CertificatesPage isCandidate={false} />}
    />
    <Route
      path=':username/:nameTag/:protected?/conhecimentos'
      element={<KnowledgesPage isCandidate={false} />}
    />
    <Route
      path=':username/:nameTag/:protected?/experiencias'
      element={<ProfessionalExperiencesPage isCandidate={false} />}
    />
    <Route
      path=':username/:nameTag/:protected?/educacao'
      element={<EducationPage isCandidate={false} />}
    />
    <Route
      path=':username/:nameTag/:protected?/vivencias'
      element={<LifeExpiriencesPage isCandidate={false} />}
    />
  <Route
      path=':username/:nameTag/:protected?/pcd'
      element={<PWDPage isCandidate={false} />}
    />
  <Route
      path=':username/:nameTag/:protected?/curriculos'
      element={<CurriculumsPage isCandidate={false} />}
    />
  </Route>
);

const candidateRoutes = (
  <Route path='/vacancies'>
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId'
      element={<CandidatePage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/selos'
      element={<TaqeStampsPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/certificacoes'
      element={<CertificatesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/conhecimentos'
      element={<KnowledgesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/atividades-da-vaga'
      element={<VacancyActivitiesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/experiencias'
      element={<ProfessionalExperiencesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/educacao'
      element={<EducationPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/vivencias'
      element={<LifeExpiriencesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/pcd'
      element={<PWDPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/curriculos'
      element={<CurriculumsPage />}
    />
  </Route>
);

const publicCandidateRoutes = (
  <Route path='/public/vacancies'>
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId'
      element={<PublicCandidatePage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/selos'
      element={<TaqeStampsPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/certificacoes'
      element={<CertificatesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/conhecimentos'
      element={<KnowledgesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/atividades-da-vaga'
      element={<VacancyActivitiesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/experiencias'
      element={<ProfessionalExperiencesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/educacao'
      element={<EducationPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/vivencias'
      element={<LifeExpiriencesPage />}
    />
    <Route
      path=':vacancyId/candidates/:vacancyCandidateId/pcd'
      element={<PWDPage />}
    />
  </Route>
);

const Main = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      {userRoutes}
      {candidateRoutes}
      {publicCandidateRoutes}
      <Route
        path='*'
        element={
          <ErrorPage code={404} title='Página não encontrada'>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              Voltar
            </Button>
          </ErrorPage>
        }
      />
    </Routes>
  );
};

export default Main;

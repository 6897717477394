export const MBTI_DESCRIPTIONS: Record<string, Record<string, Record<string, string>>> = {
  'ESFJ': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'ganham energia na interação com outras pessoas. São geralmente descritos como expansivos e sociáveis.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'estão mais focados no presente do que no futuro. Estão interessados em detalhes imediatos e concretos, ao invés de informações abstratas ou teóricas.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'tendem a tomar decisões baseadas em sentimentos pessoais , emoções e preocupação com os outros. E a pensar mais no impacto pessoal de uma decisão do que em critérios objetivos.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'são organizados e gostam de planejar as coisas com antecedência. O planejamento os ajuda a se sentirem mais no controle do mundo ao redor deles. '
    },
  },
  'ENFJ': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'têm uma personalidade expansiva e gostam de passar tempo com outras pessoas. Estar em ambientes sociais ajuda a se sentirem energizados.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'gostam mais de pensar sobre o futuro do que sobre o presente. Muitas vezes, podem se tornam tão focados no panorama geral, que perdem de vista os detalhes imediatos.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'quanto à tomada de decisões, dão mais ênfase a considerações pessoais do que a critérios objetivos. Sua preocupação maior é como as decisões impactarão os outros.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'são organizados e gostam de estrutura e planejamento cuidadoso. Cumprir uma programação prevista ajuda a se sentirem no controle do mundo ao redor deles.'
    },
  },
  'ISTJ': {
    'energy': {
      'title': 'Introversão (I)',
      'description':
          'preferem passar o tempo sozinhos ou com pequenos grupos de amigos íntimos.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'preferem se concentrar nos detalhes do que pensar em informações abstratas.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'tomam decisões baseadas na lógica e em dados objetivos, ao invés de se apoiarem em sentimentos pessoais.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'são planejadores - gostam de planejar cuidadosamente as coisas com antecedência.'
    },
  },
  'ISFJ': {
    'energy': {
      'title': 'Introversão (I)',
      'description': 'são introvertidos e tendem a ser quietos e reservados.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'preferem informações concretas, em vez de teorias abstratas.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'se baseiam mais em considerações pessoais ao invés de informações objetivas.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description': 'são planejadores e tendem a ser muito bem organizados.'
    },
  },
  'INFJ': {
    'energy': {
      'title': 'Introversão (I)',
      'description':
          'tendem a ser reservados, tranquilos e ter um pequeno círculo de amigos íntimos.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'preferem conceitos abstratos e tendem a concentrar-se mais no panorama geral do que em detalhes concretos.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'baseiam-se mais em preocupações pessoais do que em fatos objetivos, ao tomar decisões.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'gostam de exercer o controle através do planejamento, organização e tomada de decisões, o mais cedo possível.'
    },
  },
  'ENTP': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'gostam de interagir com uma grande variedade de pessoas. Adoram conversar e envolver os outros em debates.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'estão mais focados no futuro, em vez de em detalhes imediatos. Podem iniciar projetos e nunca terminá-los, pois estão mais focados no panorama geral do que nas necessidades do presente.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'são lógicos e objetivos. Ao tomar decisões, colocam um peso maior na evidência racional do que na informação subjetiva, emocional.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'tendem a reservar seu julgamento. Em vez de tomar uma decisão ou se comprometer com um plano de ação, eles preferem esperar e ver o que acontece.'
    },
  },
  'ESFP': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'gostam de interagir com outras pessoas e se sentem energizados após gastarem tempo socializando.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'preferem se concentrar no aqui e agora, em vez de pensar sobre o futuro. distante. Também preferem aprender sobre fatos concretos, em vez de ideias teóricas.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'ao tomarem decisões, dão maior ênfase a sentimentos pessoais do que a lógica e fatos.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'não gastam muito tempo em planejamento e organização. Em vez disso, gostam de manter suas opções em aberto.'
    },
  },
  'INTJ': {
    'energy': {
      'title': 'Introversão (I)',
      'description': 'tendem a ser introvertidos e preferem trabalhar sozinhos.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'preferem ter uma visão geral, e focar em informações abstratas, ao invés de detalhes concretos.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'valorizam mais a lógica e informações objetivas do que emoções subjetivas.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'devido ao seu desejo de controle e ordem, preferem fazer planos com antecedência.'
    },
  },
  'ENFP': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'gostam de interagir com várias pessoas. Socializar os ajuda a se sentirem energizados e renovados.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'geralmente focam num mundo de possibilidades. São bons em raciocínio abstrato e preferem não se concentrar em pequenos detalhes. São criativos e voltados para o futuro.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'quanto à tomada de decisões, ENFPs são mais voltados a sentimentos e valores do que à lógica e critérios objetivos. Tendem a seguir seu coração, e a serem empáticos com os outros, deixando suas emoções orientarem suas decisões.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'são flexíveis e gostam de manter suas opções em aberto. São espontâneos e altamente adaptáveis a mudanças. Também não gostam de rotina e podem ter problemas com a desorganização e procrastinação.'
    },
  },
  'INFP': {
    'energy': {
      'title': 'Introversão (I)',
      'description':
          'tendem a ser reservados, tranquilos e ter um pequeno círculo de amigos íntimos.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'preferem conceitos abstratos e tendem a concentrar-se no panorama geral ao invés de em detalhes concretos.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'baseiam-se mais em preocupações pessoais do que em fatos objetivos, ao tomar decisões.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'gostam de manter suas opções em aberto e sentem-se limitados por estrutura e planejamento.'
    },
  },
  'ISTP': {
    'energy': {
      'title': 'Introversão (I)',
      'description': 'tendem a ser quietos e reservados.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'preferem informações concretas, em vez de conceitos abstratos ou teóricos.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'tomam decisões com base na lógica ao invés de se apoiarem em emoções subjetivas.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'preferem não planejar as coisas com antecedência e gostam de manter suas opções em aberto.'
    },
  },
  'ENTJ': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'gostam de passar o tempo com outras pessoas. Têm fortes habilidades verbais e interagir com os outros ajuda a se sentirem energizados.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'preferem pensar no futuro, em vez de se concentrarem no aqui e agora. Costumam considerar informações abstratas e teóricas mais interessantes que detalhes concretos.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'quanto à tomada de decisões, ENTJs dão maior ênfase à informação objetiva e lógica. O sentimento pessoal e as emoções dos outros não são levados muito em conta em suas escolhas.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'são planejadores. A tomada de decisões, bem como ter uma agenda ou um plano de ação lhes dá uma sensação de previsibilidade e controle.'
    },
  },
  'INTP': {
    'energy': {
      'title': 'Introversão (I)',
      'description':
          'preferem socializar com um pequeno grupo de amigos íntimos.'
    },
    'information': {
      'title': 'Intuição (N)',
      'description':
          'tendem a focar no panorama geral, ao invés de em pequenos detalhes.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'são lógicos e baseiam suas decisões em informações objetivas, ao invés de se apoiarem em sentimentos subjetivos.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'gostam de manter suas opções em aberto e sentem-se limitados por estrutura e planejamento.'
    },
  },
  'ESTP': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'são extrovertidos e gostam de passar o tempo com um amplo círculo de amigos e conhecidos.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'estão interessados no aqui e agora e são mais propensos a se concentrar em detalhes do que ter uma visão mais ampla das coisas.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'são lógicos. Ao tomar decisões, valorizam mais a objetividade do que sentimentos pessoais.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'não gostam de se prender a um planejamento excessivo. Em vez disso, preferem improvisar e manter suas opções em aberto.'
    },
  },
  'ESTJ': {
    'energy': {
      'title': 'Extroversão (E)',
      'description':
          'são expansivos e gostam de liderar e supervisionar pessoas.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'apreciam fatos concretos, ao invés de informações abstratas.'
    },
    'decisions': {
      'title': 'Pensamento (T)',
      'description':
          'confiam na informação objetiva e lógica para tomar decisão ao invés de se apoiarem em sentimentos pessoais.'
    },
    'lifestyle': {
      'title': 'Julgamento (J)',
      'description':
          'apreciam controle e ordem, de modo que gostam de planejar as coisas com antecedência'
    },
  },
  'ISFP': {
    'energy': {
      'title': 'Introversão (I)',
      'description':
          'são introvertidos, tendem a ser reservados e tranquilos, especialmente em relação a pessoas que não conhecem bem. Preferem passar o tempo com um grupo próximo da família e amigos.'
    },
    'information': {
      'title': 'Sensação (S)',
      'description':
          'gostam de se concentrar nos detalhes. Passam mais tempo pensando sobre o aqui e agora, em vez de se preocupar com o futuro. Também preferem informações concretas a teorias abstratas.'
    },
    'decisions': {
      'title': 'Sentimento (F)',
      'description':
          'se preocupam mais com interesses pessoais do que com informações objetivas e lógicas.'
    },
    'lifestyle': {
      'title': 'Percepção (P)',
      'description':
          'gostam de manter suas opções em aberto, sendo que, muitas vezes, atrasam a tomada de decisões, a fim de ver se as coisas mudam ou se novas opções surgem.'
    },
  }
};

export default MBTI_DESCRIPTIONS;
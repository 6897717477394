import styled from 'styled-components';
import defaultPic from '../../assets/default-picture.png';

interface IImage {
  photo?: any;
  hasBorder?: boolean;
  isFloating?: boolean;
}


export const CircleImage = styled.div<IImage>`
  background-image: url(${props => props.photo ? props.photo : defaultPic});
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  height: 11.875rem;
  overflow: hidden;
  width: 11.875rem;

  ${props => props.hasBorder && `border: 4px solid #00DAA1;`}
  ${props => props.isFloating && `margin-top: -7.9375rem;`}

  @media (max-width: 1400px) {
    width: 10rem;
    height: 10rem;
  }
`;

export const MediumCircleImage = styled(CircleImage)`
  width: 8rem;
  height: 8rem;
`;

export const SmallCircleImage = styled(CircleImage)`
  width: 4.125rem;
  height: 4.125rem;
`;

export const VideoAvatar = styled.div<IImage>`
    width: 11.875rem;
    height: 11.875rem;
    border: 3px var(--primary-color) solid;
    border-radius: 50%;
    position: relative;
    background-image: ${props => props.photo ? `url(${props.photo})` : `url(${defaultPic})`};
    background-size: cover;
    background-position: center;
    margin-top: -7.9375rem;
    cursor: pointer;
    transition: 0.3s;

    z-index: 1;
    
    :hover {
      border: 3px var(--primary-color-light) solid;
      opacity: 0.95;

      > svg {
        color: var(--primary-color-light);
      }
    }

    > svg {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      position: absolute;
      left: 0;
      bottom: 0;
      color: var(--primary-color);
      background: white;
      transition: 0.3s;
    }
`

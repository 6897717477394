import { ChartPieIcon, ChartRadar } from "../../../../../components/Charts";
import { setPersonality } from "../../../../../components/Stamps";
import { serializeStamps } from "../../../../../helpers/SerializeUserData";
import { TranslateStamps } from "../../../../../helpers/Translator";
import { User } from "../../../../../models/user";
import Card from "../Card";
import CardContent from "../Card/CardContent";
import Text from "../Text";
import { StyledCardHeader } from "./styles";

interface TaqeStampsProps {
  user: User;
}

export function TaqeStamps({ user }: TaqeStampsProps) {
  const stamps = serializeStamps(user);

  return (
    <>
      {stamps.map((stamp) => {

        if (stamp.key === "competencies") {
          return null;
        };
        
        return (
          <Card key={stamp.key}>
            <StyledCardHeader> 
              {stamp.key !== "personality" ? (
                <ChartPieIcon data={stamp.value} />
              ) : (
                <ChartRadar
                  data={setPersonality(stamp.value.indicators)}
                  isIcon={true}
                />
              )}
            </StyledCardHeader>
            <CardContent><Text variant="title">{TranslateStamps[stamp.key]}</Text></CardContent>
          </Card>
        );
      })}
    </>
  );
}

import React from 'react';
import { Section as Div } from './styles';

type Props = {
  bordered?: boolean;
  children?: any;
};

const Section: React.FC<Props> = ({ bordered, children }: Props) => (
  <Div bordered={bordered} className='ps-4 pe-4 ps-lg-0 pe-lg-0'>
    {children}
  </Div>
);

export default Section;

import React from 'react';
import { Section } from './styles';
import { GetYear } from '../../helpers/Dates';

type LifeExperieceProps = {
  experience: any;
};

export const LifeExperiece: React.FC<LifeExperieceProps> = ({
  experience,
}: LifeExperieceProps) => {
  return (
    <Section bordered={true}>
      <div className='d-flex align-items-center '>
        <div className='infos'>
          <h1>{experience.cause}</h1>
        </div>
      </div>
      <hr />

      <div className='infos'>
        <h2>{experience.office}</h2>
        <p>
          {GetYear(experience.in)} -{' '}
          {experience.workHere ? (
            <span
              style={{
                fontWeight: 600,
                color: 'rgba(51, 226, 180, 1)',
              }}
            >
              Atual
            </span>
          ) : (
            GetYear(experience.until)
          )}
        </p>
        <p>{experience.description} </p>
      </div>
    </Section>
  );
};

import { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import About from './About';
import { TaqeStampsLinks } from './TaqeStamps';
import { CertificatesLinks } from './Certificates';
import { ProfessionalExperiencesLinks } from './ProfessionalExpiriences';
import { EducationLinks } from './Educations';
import { KnowledgesLinks } from './Knowledges';
import { LifeExperiencesLinks } from './LifeExpiriences';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar/index';
import { UserProfessionalIdentity } from '../models/userProfessionalIdentityModel';
import { User } from '../models/user';
import UserContext from '../contexts/UserContext';
import { PersonWithDisabilityLinks } from './PersonWithDisability';

import { getUserData } from '../services/userService';
import Loading from '../components/Loader';
import WebShare from '../components/WebShare';
import Icon from '../components/Icon';

export const UserPage = () => {
  const { user, setUser } = useContext(UserContext);
  let [searchParams] = useSearchParams();
  const [profile, setProfile] = useState<UserProfessionalIdentity>({} as UserProfessionalIdentity);
  const [navbarHeight, setNavbarHeight] = useState<number | undefined>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { username, nameTag } = useParams();
  const location = useLocation();
  const url = location.pathname;
  const isExternalLink = !Boolean(url.split('/').find(param => param.includes('protected')));
  const sharedUrl = isExternalLink ? url : `${window.location.origin}${url.split('/').filter(urlSegment => !urlSegment.includes('protected')).join('/')}`;

  const settingNavbarHeight = () => {
    const height = document.getElementById('mobile-nav')?.clientHeight;
    setNavbarHeight(height);
  };

  useEffect(() => {
    if (!user._id) {
      setShowLoading(true);
      getUserData({
        username,
        nameTag
      })
        .then((response) => {
          const data = response.data;

          setProfile(data);
          setUser(data.user);
        })
        .catch((err) => {})
        .finally(() => setShowLoading(false));
    }

    if (user) {
      setProfile({ user } as any);
    }

    settingNavbarHeight();
  }, [navbarHeight, user, searchParams, username, nameTag, setUser]);

  useEffect(() => {
    function blockCtrlP(e: KeyboardEvent) {
      if (e.ctrlKey && e.code === 'KeyP') {
        e.preventDefault();
      }
    }
    document.addEventListener('keydown', blockCtrlP);
    return () => {
      document.removeEventListener('keydown', blockCtrlP);
    };
  }, []);


  return showLoading ? (
    <Loading />
  ) : (
    <Container fluid className='g-0'>
      {profile && profile.user && (
        <Row className='g-0'>
          
          <Col
            lg={3}
            className='d-none d-lg-flex cover align-items-lg-start justify-content-lg-end'
          >
            <Sidebar
              basicInfo={profile.user.basicInformation}
              address={profile.user.address}
              photo={profile.user.photo}
              presentationVideo={profile.user.presentationVideo}
              isCandidate={false}
              isExternalLink={isExternalLink}
            />
          </Col>
          <Col lg={9}>
            <div className='d-lg-none cover cover-sm'></div>
            <Row className='justify-content-center pt-lg-5'>
              <Col lg={10}>
                <About
                  basicInformation={profile.user.basicInformation}
                  aboutMe={profile.user.aboutMe}
                  address={profile.user.address}
                  photo={profile.user.photo}
                  presentationVideo={profile.user.presentationVideo}
                  documents={profile.user.identificationDocuments}
                  contact={profile.user.contact}
                  socialNetwork={profile.user.socialNetworks}
                  isCandidate={false}
                  isExternalLink={isExternalLink}
                />

                <TaqeStampsLinks candidate={profile.user} isCandidate={false} />

                {profile.user.certificates && !!profile.user.certificates.length && (
                  <CertificatesLinks
                    certificates={profile.user.certificates}
                    isCandidate={false}
                  />
                )}

                <EducationLinks
                  educations={profile.user.education}
                  levelEducation={profile.user.basicInformation?.levelEducation}
                  isCandidate={false}
                />

                <ProfessionalExperiencesLinks
                  experiences={profile.user.professionalExperience}
                  isCandidate={false}
                />

                <KnowledgesLinks candidate={profile.user as User} isCandidate={false}/>

                { profile.user.disabilities &&  <PersonWithDisabilityLinks disabilities={profile.user.disabilities} isCandidate={false}/> }

                <LifeExperiencesLinks experiences={profile.user.volunteering} isCandidate={false} />

                <Footer paddingBottom={navbarHeight} />

                <WebShare
                  title="Compartilhe sua Identidade profissional:"
                  text="Conheça meu CV TAQE: "
                  url={sharedUrl}
                  buttonAriaLabel="Compartilhar identidade profissional"
                  isExternalLink={isExternalLink}
                >
                    <Icon name='Share' className='icon' color='var(--white)' />
                </WebShare>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
};

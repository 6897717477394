import React from "react";
import Plyr from "plyr-react";
import { Modal } from "react-bootstrap";

import "plyr-react/plyr.css";
import '../../helpers/styles/customPlyr.css';
import '../../helpers/styles/presentationVideoModal.css';
import CloseIcon from 'remixicon-react/CloseLineIcon';

type PresentationVideoModalProps = {
  presentationVideo: string;
  isOpen: boolean;
  setShowVideoModal: (status: boolean) => void;
}

export default function PresentationVideoModal(
  { 
    presentationVideo, 
    isOpen, 
    setShowVideoModal 
  }: PresentationVideoModalProps) {

  return (
    <Modal
      show={isOpen} 
      onHide={() => setShowVideoModal(false)} 
      animation={false}
      contentClassName='custom-modal-content'
    >
      <Modal.Header className='custom-modal-header' style={{ display: "flex", justifyContent: "end", paddingRight: "2.5rem" }}>
            <CloseIcon size={30} onClick={() => setShowVideoModal(false)} className='close-modal' />
      </Modal.Header>
        <Modal.Title />
          <Plyr
            options={{
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume'],
            }}
            source={{
              type: 'video',
              title: 'Presentation Video',
              sources: [
                {
                  src: presentationVideo,
                  type: 'video/mp4',
                },
              ]
            }}
            />
    </Modal>
  )
}
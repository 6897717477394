import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Curriculums } from '../models/user';
import { useKeenSlider } from 'keen-slider/react';
import Badge from '../components/Badge';
import PageModal from '../components/PageModal';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import 'keen-slider/keen-slider.min.css';
import Loading from '../components/Loader';
import { Curriculum } from '../components/Curriculum';

type CurriculumsLinksProps = {
  curriculums?: Curriculums[];
  isCandidate?: Boolean;
};

export const CurriculumsLinks: React.FC<CurriculumsLinksProps> = ({
  curriculums,
  isCandidate = true
}: CurriculumsLinksProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      breakpoints: {
        '(min-width: 420px)': {
          slides: { perView: 2, spacing: 10 },
        },
        '(min-width: 760px)': {
          slides: { perView: 5, spacing: 14 },
        },
      },
      slides: {
        perView: 2,
        spacing: 10,
      },
    },
    []
  );

  return (
    <Fragment>
      {curriculums && !!curriculums.length &&
        <div className='keen-slider mb-4' ref={sliderRef}>
          <Link
            to={`curriculos${isCandidate ? `?token=${token}` : '' }`}
            className='keen-slider__slide'
            key='curriculums'
          >
            <Badge
              title='Currículos'
            />
          </Link>
        </div>
      }
    </Fragment>
  );
};

export const CurriculumsPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    }

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let curriculums: any;

  if (!showLoading) {
    curriculums = user?.curriculums || [] as Curriculums[];
  }

  return showLoading ? (<Loading />) : (<PageModal nav={<DetailsNav navigation={menuHelper} title="Currículos anexos" isCandidate={isCandidate} />} content={<Curriculum curriculums={curriculums} />} badge="Currículos" />);
};

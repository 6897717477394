import { Container } from "react-bootstrap";
import styled from "styled-components";

export const WrapperContainer = styled(Container)`

    .content-container {
        padding-left: 6.688rem;
        margin-top: 5.313rem;
    }

    .content-section {
        margin-top:  3rem;
        width: 90%;
    }

    @media (max-width: 768px) {
        .page-inner-wrap {
            min-height: 0;
        }

        .content-container {
            padding-left: 0;
            padding-top: 1rem;
            width: 90%;
            /* height: calc(100vh - 1rem); */
            margin: 0 auto;
        }

        .content-section {
            width: 100%;
        }

    }
`

export const Badge = styled.span`
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 0.5rem 0.688rem;
    border-radius: 40px;
    font-size: 1rem;
    font-weight: 600;
`
import styled from "styled-components";

export const CardWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  text-align: center;

  margin-top: 8px;

  border: 1px solid rgba(223, 223, 223, 1);
  border-radius: 8px;

  text-transform: uppercase;
  color: var(--primary-color);

  min-height: 8.844rem;
  min-width: 7.844rem;

  box-sizing: content-box;
`;
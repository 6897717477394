import styled from "styled-components";

export const EduactionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  div {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    strong {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    li {
      list-style: none;
      position: relative;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
      color: #68656E;
      font-size: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 0.25rem;
        left: 0;
        width: 8px;
        height: 8px;
        background: var(--primary-color);
        border-radius: 50%;
      }
    }

    strong {
      font-weight: bold;
      color: var(--primary-color);
      margin-bottom: 0.5rem ;
    }

    p {
      margin-bottom: 0.5rem;
      color: #68656E;
    }
  }
`;

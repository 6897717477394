import React, { Fragment } from 'react';
import {
  CardInfo,
  CardInfoIcon,
  CardInfoTitle,
  CardInfoSubtitle,
  CardInfoExcerpt,
} from './styles';

type Props = {
  icon?: any;
  title: string;
  subtitle?: string;
  description?: any;
  full?: boolean;
  outline?: boolean;
  details?: any;
};

const Card: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  description,
  details,
  full = false,
  outline = false
}: Props) => {
  let subContent;

  if (full || outline) {
    subContent = (
      <div className='card-info-header'>
        <CardInfoTitle full={full}>{title}</CardInfoTitle>{' '}
        <CardInfoSubtitle full={full}>{subtitle}</CardInfoSubtitle>
        {details &&<CardInfoExcerpt full={true}>{details}</CardInfoExcerpt>}
      </div>
    );
  } else {
    subContent = (
      <Fragment>
        <CardInfoTitle>{title}</CardInfoTitle>{' '}
        <CardInfoSubtitle full={full}>{subtitle}</CardInfoSubtitle>
      </Fragment>
    );
  }
  return (
    <CardInfo full={full} outline={outline}>
      {icon && <CardInfoIcon>{icon}</CardInfoIcon>}
      {subContent}
      {description && (<CardInfoExcerpt full={full}>{description}</CardInfoExcerpt>)}
    </CardInfo>
  );
};

export default Card;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section/index';
import { Disabilities } from '../models/user';
import { TranslateDisabilities } from '../helpers/Translator';
import { useKeenSlider } from 'keen-slider/react';
import Badge from '../components/Badge';
import PageModal from '../components/PageModal';
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import CandidateContext from '../contexts/Candidate';
import UserContext from '../contexts/UserContext';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import 'keen-slider/keen-slider.min.css';
import { GeneralDisability, HearingImpaired, VisuallyImpaired } from '../components/PWD';
import Loading from '../components/Loader';

type PersonWithDisabilityLinksProps = {
  disabilities: Disabilities;
  isCandidate?: Boolean;
};

const disabilitiesBase = [
  { key: "visual", },
  { key: "hearing", },
  { key: "phisic", },
  { key: "mental", },
  { key: "rehabilitated" },
];

const setPWDHelper = (disabilities: string[] = []): false | Record<any, any>[] => {
  return disabilities && disabilitiesBase.filter((disability) => disabilities.includes(disability.key));
};

export const PersonWithDisabilityLinks: React.FC<PersonWithDisabilityLinksProps> = ({
  disabilities,
  isCandidate = true
}: PersonWithDisabilityLinksProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      breakpoints: {
        '(min-width: 420px)': {
          slides: { perView: 2, spacing: 10 },
        },
        '(min-width: 760px)': {
          slides: { perView: 5, spacing: 14 },
        },
      },
      slides: {
        perView: 2,
        spacing: 10,
      },
    },
    []
  );

  const disabilitiesList = setPWDHelper(disabilities.types);
  return (
    <Fragment>
      <Section bordered={true}>
        <div
          className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'
          id='PCD'
        >
          <h2 className='h6 mb-0'>Pessoa com deficiência</h2>
          <p className='mb-0'>
            <small className="message-details">Clique nos cards para ver mais informações</small>
          </p>
        </div>

        <div className='keen-slider' ref={sliderRef}>
          {disabilitiesList && !!disabilitiesList.length && disabilitiesList.map((disability: any) => {
            return (
              <Link
                to={`pcd?page=${disability.key}${isCandidate ? `&token=${token}` : '' }`}
                className='keen-slider__slide'
                key={disability.key}
              >
                <Badge
                  title={TranslateDisabilities[disability.key]}
                />
              </Link>
            );
          })}
          {disabilitiesList && !disabilitiesList.length && <p>Não preenchido</p>}
        </div>
      </Section>
    </Fragment>
  );
};

export const PWDPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);

  // const { candidate, setCandidate } = useContext(CandidateContext);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');

  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);


  let menuHelper: any[] = [];
  let activitiesPage: any = {};
  let disabilities: any;

  if (!showLoading) {
    const disabilitiesTypes = user?.disabilities?.types || [];
    const activitiesHelper = setPWDHelper( disabilitiesTypes)|| [];
    menuHelper = (setPWDHelper(disabilitiesTypes) || []).map(disability => ({ ...disability, title: TranslateDisabilities[disability.key] }));
    
    activitiesPage = user && activitiesHelper.find(disability => disability.key === page);
  
    disabilities = user?.disabilities || {} as Disabilities;
  }

  const mountContent = (page: string): any => {
    const dynamicContent: Record<string, any> = {
      visual: <VisuallyImpaired disabilities={disabilities} />,
      hearing: <HearingImpaired disabilities={disabilities} />,
      phisic: <GeneralDisability disabilities={disabilities} />,
      mental: <GeneralDisability disabilities={disabilities} />,
      rehabilitated: <GeneralDisability disabilities={disabilities} />,
    }

    return dynamicContent[page] || (<p>Tela ainda não implementada :/</p>);
  }

  return showLoading ? (<Loading />) : (<PageModal nav={<DetailsNav navigation={menuHelper} title="Pessoa com deficiência" isCandidate={isCandidate} />} content={mountContent(page!)} badge={TranslateDisabilities[activitiesPage?.key]} />);
};

export const TranslateLevelEducation = (level: string) => {
  let name: string = '';
  switch (level) {
    case 'ens-sup-comp':
      name = 'Ensino superior completo';
      break;
    default:
      name = '';
      break;
  }

  return name;
};

export const TranslateVacancyActivities: Record<string, string> = {
  'concentratedAttentionTest': 'Teste de concentração',
  'onlineEssays': 'Redação Online',
  'typingTest': 'Teste de digitação',
  'uploadedVideosURLs': 'Vídeo',
  'testResults': 'Testes Customizados',
  'supplementaryQuestions': 'Perguntas complementares'
};

export const TranslateKnowledges: Record<string, string> = {
  'softwares': 'Tecnologia',
  'languages': 'Idiomas'
};

export const TranslateStamps: Record<string, string> = {
  'personality': 'Personalidade',
  'english': 'Inglês',
  'generalKnowledge': 'Conhecimentos Gerais',
  'logic': 'Lógica',
  'logicAdvanced': 'Lógica Avançada',
  'portuguese': 'Português',
  'level1': 'Nível 1',
  'level2': 'Nível 2',
  'level3': 'Nível 3',
  'level4': 'Nível 1 Avançado',
  'competencies': 'Competências'
};

export const TranslateEducationType: Record<string, string> = {
  'common': 'Presencial'
};

export const TranslateCompetenciesType: Record<string, string> = {
  'emotionalControl': 'Controle Emocional',
  'flexibility': 'Flexibilidade',
  'focusOnResults': 'Foco em Resultados',
  'iniciative': 'Iniciativa',
  'organization': 'Organização',
  'personalGuidance': 'Orientação de Pessoas'
};

export const RCA_Profiles: Record<string, string> = {
  entrepreneur: 'Empreendedor',
  ambassador: 'Embaixador',
  representative: 'Representante',
  disqualified: 'Não aderente',
};


export const GNS_Profiles: Record<string, string> = {
  "gns": "GNS",
  "sales": "Vendas",
  "attendance": "Atendimento",
  "administrative": "Administrativo",
};


export const CompetenciesSantanderProfiles: Record<string, string> = {
  'commercial': 'Atividades comerciais',
  'attendance': 'Atividades de atendimento',
  'proceduralActivity': 'Atividades de processo',
};

export const TranslateDisabilities: Record<string, string> = {
  "visual": "Visual",
  "hearing": "Auditiva",
  "phisic": "Física",
  "mental": "Intelectual",
  "rehabilitated": "Reabilitada",
};

export const HEARING_LOSS: Record<string, string> = {
  'lightDeafness': 'Surdez Leve',
  'moderateDeafness': 'Surdez Moderada',
  'acuteDeafness': 'Surdez Acentuada',
  'severeDeafness': 'Surdez Severa',
  'deepDeafness': 'Surdez Profunda',
  'anacusia': 'Anacusia',
  'dysacusis': 'Disacusia',
};

import styled from 'styled-components';

export const List = styled.div`
  display: block;
  border-bottom: 1px solid var(--border-color);
  padding: 0.469rem 0;
  margin-bottom: 0.938rem;
  p {
    margin: 0 0 .3rem 0;
    strong {
      font-weight: 700;
      color: var(--black);
    }
  }
`;

export const CardAccordion = styled.section`

`;

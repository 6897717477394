import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import UserContext from '../../contexts/UserContext';
import Loading from '../Loader';
import { Badge, WrapperContainer } from './styles';

type PageModalProps = {
  nav?: any;
  content?: any;
  badge?: string;
}

const PageModal: React.FunctionComponent<PageModalProps> = ({ nav, content, badge }: PageModalProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    setShowLoading(!user._id);
  }, [user]);
  return (
    showLoading ? <Loading /> :
      <WrapperContainer fluid className='g-0 page-inner'>
        <Row className='g-0 page-inner-wrap'>
          <Col md={1} className='d-none d-md-block cover'></Col>
          <Col md={3} sm={12} className='page-inner-sidebar'>
              {
                nav
              }
          </Col>
          <Col md={8} sm={12} className="content-container">
            {badge && <Badge title={badge}>
              {badge.length > 30 ? `${badge.substring(0, 30)}...` : badge}
            </Badge>}
            <section className='content-section'>
              {content}
            </section>
          </Col>
        </Row>
      </WrapperContainer>
  );
};

export default PageModal;

import styled from 'styled-components';

interface ISection {
  bordered?: boolean;
}

export const Section = styled.section<ISection>`
  border-top: ${(props) =>
    props.bordered ? '1px solid rgba(108, 123, 138, 0.08)' : 'none'};
  padding: 1.563rem 0.625rem;
  position: relative;

  .h6 {
    font-size: 1.25rem;
  }
`;

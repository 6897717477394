import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section/index';
import Card from '../components/Card';
import { GetYear } from '../helpers/Dates';
import { Settings } from 'http2';
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import { Education } from '../components/Education';
// import CandidateContext from '../contexts/Candidate';
import UserContext from '../contexts/UserContext';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import PageModal from '../components/PageModal';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import { ProfessionalExperience } from '../components/ProfessionalExperience';
import Loading from '../components/Loader';

type ProfessionalExperieceItem = {
  organization: string;
  office: string;
  standardProfessionalArea: {
    standardListId: string;
    name: string;
    validated: boolean;
  };
  standardSpecialization: {
    standardListId: string;
    name: string;
    validated: boolean;
    professionalAreaId: string;
  };
  state: string;
  city: string;
  branch: string;
  roleLevel: string;
  wage: number;
  in: Date;
  until: string;
  workHere: boolean;
  description: string;
  _id: string;
  untilYear: Settings;
};

// export type Education = {
//   type: string;
//   institution: string;
//   formation: string;
//   typeCourse: ['common'];
//   state: string;
//   in: string;
//   until: string;
//   studyHere: boolean;
//   _id: string;
//   untilYear: string;
//   name: string;
// };

type ProfessionalExperiencesLinksProps = {
  experiences: ProfessionalExperieceItem[];
  isCandidate?: Boolean;
};


export const ProfessionalExperiencesLinks: React.FC<
  ProfessionalExperiencesLinksProps
> = ({ experiences, isCandidate = true }: ProfessionalExperiencesLinksProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  return (
    <Fragment>
      <Section bordered={true}>
        <div
          className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'
          id='experiencias'
        >
          <h2 className='h6 mb-0'>Experiências</h2>
          <p className='mb-0'>
            <small className='message-details'>
              Clique nos cards para ver mais informações
            </small>
          </p>
        </div>
        {experiences &&
          !!experiences.length &&
          experiences.map((experience: any, index: number) => (
            <Link
              to={`experiencias?page=${experience._id}${isCandidate ? `&token=${token}` : '' }`}
              className='keen-slider__slide mb-2'
              key={experience._id}
            >
              <Card
                full={true}
                title={experience.organization}
                description={
                  <Fragment>
                    {experience.city}
                    <br />
                    {GetYear(experience.in)} -{' '}
                    {experience.workHere ? (
                      <span
                        style={{
                          fontWeight: 600,
                          color: 'rgba(51, 226, 180, 1)',
                        }}
                      >
                        Trabalhando Atualmente
                      </span>
                    ) : (
                      GetYear(experience.until)
                    )}
                    <br />
                    {experience.description}
                  </Fragment>
                }
              />
            </Link>
          ))}
        {experiences && !experiences.length && <p>Não possui experiência.</p>}
        {!experiences && <p>Não preenchido.</p>}
      </Section>
    </Fragment>
  );
};

export const ProfessionalExperiencesPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');
  
  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let expiriencesPage: any = {};

  if (!showLoading) {
    const experiences = user.professionalExperience;
    
    menuHelper = experiences.map((experience: any) => ({
      ...experience,
      title: experience.organization,
      key: experience._id
    }));
  
    expiriencesPage = experiences.find((experience: any) => experience._id === page);
  }

  return showLoading ? (<Loading />) : (<PageModal nav={<DetailsNav navigation={menuHelper} title="Experiências" isCandidate={isCandidate} />} content={<ProfessionalExperience experience={expiriencesPage} />} badge={expiriencesPage?.organization} />);
};

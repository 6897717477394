import styled, { css } from "styled-components";


export const DisplayLayout = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  height: 0;
  width: 0;
`

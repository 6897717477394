import React, { Fragment, useEffect } from 'react';
import Main from './components/Main';
import Navbar from './components/Nav/Navbar';
import { UserProvider } from './contexts/UserContext'
import { CandidateProvider } from './contexts/Candidate';

function App() {
  useEffect(() => {
    window.addEventListener('message', function(event) {
      if (event.origin === 'https://app.taqe.mobi/') {
        const data = event.data;
      }
    });
  }, [])
  return (
    <UserProvider>
      <CandidateProvider>
        <Fragment>
          <Main />
          <Navbar />
        </Fragment>
      </CandidateProvider>
    </UserProvider>
  );
}

export default App;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import Section from '../components/Section';
import 'keen-slider/keen-slider.min.css';
import Card from '../components/Card';
import Slider from '../components/Slider';
import { Link, useParams, useSearchParams } from 'react-router-dom';
// import CandidateContext from '../contexts/Candidate';
import { getCandidateData } from '../services/candidate';
import { getUserData } from '../services/userService';
import { Certificate } from '../components/Certificates';
import PageModal from '../components/PageModal';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import UserContext from '../contexts/UserContext';
import Loading from '../components/Loader';

type CertificateItemProps = {
  active: boolean;
  certification: object;
  certificationDate: string;
  certificationFile: string;
  company: object;
  cpf: string;
  _id: string;
};

type CertifcatesProps = {
  certificates: CertificateItemProps[];
  isCandidate?: Boolean
};

export const CertificatesLinks: React.FC<CertifcatesProps> = ({
  certificates,
  isCandidate = true
}: CertifcatesProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  return (
    <Fragment>
      <Section bordered={true}>
        <div
          className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'
          id='certificacoes'
        >
          <h2 className='h6 mb-0'>Certificações</h2>
          <p className='mb-0'>
            <small className='message-details'>
              Clique nos cards para ver mais informações
            </small>
          </p>
        </div>

        <Slider>
          {certificates.map((certificate: any) => {
            const certification: any = certificate.certification || {};
            const logo = certification.logo ? certification.logo : certificate.company.logo;

            return (
              <Link
                to={`certificacoes?page=${certificate._id}${isCandidate ? `&token=${token}` : '' }`}
                className='keen-slider__slide'
                key={certificate._id}
              >
                <Card
                  title={certification.name}
                  description={`Oferecido por ${certificate.company.fantasyName}`}
                  icon={
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img src={logo} />
                  }
                />
              </Link>
            );
          })}
        </Slider>
      </Section>
    </Fragment>
  );
};

export const CertificatesPage: React.FC<any> = ({
  isCandidate = true
}) => {
  const { user, setUser } = useContext(UserContext);
  const [showLoading, setShowLoading] = useState(true);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');

  

  useEffect(() => {
    if (user._id) {
      setShowLoading(false);
      return;
    }

    if (!isCandidate) {
      getUserData({ ...params })
        .then((response: any) => {
          setUser(response.data.user);
          setShowLoading(false);
        });
      return;
    } 

    getCandidateData({ ...params, token })
      .then((response: any) => {
        setUser(response.data.user);
        setShowLoading(false);
      });

  }, [params, token, user, setUser, isCandidate]);

  let menuHelper: any[] = [];
  let certificatesPage: any = {};

  if (!showLoading) {
    const certificates = user.certificates;

    menuHelper = certificates.map((certificate) => ({
      ...certificate,
      title: certificate?.certification?.name,
      key: certificate._id,
    }));

    certificatesPage = certificates.find(
      (certificate) => certificate._id === page
    );
  }

  return showLoading ? (<Loading />) : (
    <PageModal
      nav={<DetailsNav navigation={menuHelper} title='Certificações' isCandidate={isCandidate} />}
      content={<Certificate certificate={certificatesPage} />}
      badge={certificatesPage?.certification?.name}
    />
  );
};

import React from 'react';


const Icons = {
  User: () => <svg xmlns="http://www.w3.org/2000/svg" ><g transform="translate(.922 .75)" fill="none" strokeLinecap="round" strokeWidth="1.5"><path data-name="Path 42" d="m0 20.02.79-2.88c2.61-9.5 13.81-9.5 16.42 0l.79 2.88" strokeLinejoin="round" /><circle cx="5" cy="5" r="5" transform="translate(4)" strokeLinejoin="bevel" /></g></svg>,
  Certificate: () => <svg xmlns="http://www.w3.org/2000/svg"><g><path data-name="Path 8" d="m8.815 11.655 2.205 2.2 4.645-4.04" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" /><path data-name="Path 131" d="m21.026 9.276.35-2.2a1.19 1.19 0 0 0-.76-1.32l-2.07-.8-.79-2.06a1.19 1.19 0 0 0-1.31-.76l-2.2.35-1.72-1.4a1.2 1.2 0 0 0-1.52 0l-1.73 1.4-2.2-.35a1.19 1.19 0 0 0-1.32.76l-.8 2.07-2.06.79a1.19 1.19 0 0 0-.76 1.31l.35 2.2-1.4 1.72a1.2 1.2 0 0 0 0 1.52l1.4 1.72-.35 2.2a1.19 1.19 0 0 0 .76 1.33l2.07.8.79 2.06a1.19 1.19 0 0 0 1.31.76l2.2-.35 1.72 1.4a1.2 1.2 0 0 0 1.52 0l1.72-1.4 2.2.35a1.19 1.19 0 0 0 1.33-.76l.8-2.07 2.07-.8a1.19 1.19 0 0 0 .76-1.31l-.35-2.2 1.4-1.72a1.2 1.2 0 0 0 0-1.52Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" /></g></svg>,
  Education: () => <svg xmlns="http://www.w3.org/2000/svg"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M11.417.75.75 6.676l10.667 5.926 10.666-5.926Z" /><path data-name="Path" d="M5.491 12.602v4.741a9.69 9.69 0 0 0 5.926 2.37 9.69 9.69 0 0 0 5.926-2.37v-4.741M22.083 6.676v4.741" /></g></svg>,
  Activity: () => <svg xmlns="http://www.w3.org/2000/svg"><g fill="none" strokeLinecap="round" strokeWidth="1.5"><path data-name="Path 15" d="M20.042 11.223v5.7a2 2 0 0 1-2 2H7.822a2 2 0 0 1-2-2v-13a2 2 0 0 1 2-2h5.9" strokeLinejoin="bevel" /><path data-name="Path 16" d="M16.832 18.923v1.2a2 2 0 0 1-2 2H4.622a2 2 0 0 1-2-2V6.153a2 2 0 0 1 2-2h1.2" strokeLinejoin="bevel" /><path data-name="Path 17" d="M19.402 2.173a1 1 0 0 0-1.42 0l-6 6h0l-.1 3a.21.21 0 0 0 .23.22l3-.1h0l6-6a1 1 0 0 0-.01-1.37Z" strokeLinejoin="round" /></g></svg>,
  Experience: () => <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="19.4" viewBox="0 0 21.5 19.4">
    <g id="ic-actions-bag" transform="translate(0.75 0.75)">
      <rect id="Rectangle_1" data-name="Rectangle 1" width="20" height="14" rx="2" transform="translate(0 3.9)" fill="none" stroke="#5128e2" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="1.5" />
      <path id="Path_4" data-name="Path 4" d="M7,7V5.05a2,2,0,0,1,2-2h6a2,2,0,0,1,2,2V7" transform="translate(-2 -3.05)" fill="none" stroke="#5128e2" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="1.5" fillRule="evenodd" />
    </g>
  </svg>,
  Language: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.488 36.488"><path strokeWidth=".1" d="M.05 20.159a4.792 4.792 0 0 0 4.788 4.791h15.321a4.792 4.792 0 0 0 4.791-4.791V4.838A4.792 4.792 0 0 0 20.159.05H4.838A4.792 4.792 0 0 0 .05 4.838ZM1.965 4.838a2.876 2.876 0 0 1 2.873-2.873h15.321a2.876 2.876 0 0 1 2.873 2.873v15.321a2.876 2.876 0 0 1-2.873 2.873H4.838a2.876 2.876 0 0 1-2.873-2.873ZM6.324 19.1a.956.956 0 0 0 1.285-.427l1.651-3.3h6.477l1.651 3.3a.958.958 0 0 0 1.712-.858L13.35 6.324a.994.994 0 0 0-1.366-.345.98.98 0 0 0-.347.345l-5.74 11.491a.958.958 0 0 0 .427 1.285Zm6.175-10.206 2.281 4.562h-4.562Zm.958 18.926v3.83a2.876 2.876 0 0 0 2.873 2.873h15.32a2.876 2.876 0 0 0 2.873-2.873V16.329a2.876 2.876 0 0 0-2.873-2.873h-3.83v-1.915h3.83a4.792 4.792 0 0 1 4.788 4.788V31.65a4.792 4.792 0 0 1-4.788 4.788H16.329a4.792 4.792 0 0 1-4.788-4.788v-3.83Zm15.877 2.785a13.315 13.315 0 0 1-4.88-4.1 7.753 7.753 0 0 0 1.446-1.32 11.445 11.445 0 0 0 4.24 3.687.958.958 0 0 1-.806 1.737Zm-11.956-.465a.959.959 0 0 1 .463-1.272 11.806 11.806 0 0 0 1.7-1.048h.621a7.59 7.59 0 0 0 2.771-.538 13.755 13.755 0 0 1-4.283 3.323.958.958 0 0 1-1.27-.465Z" data-name="Path 500" /></svg>,
  Computer: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.911 28.374"><g data-name="Vrstva 152" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="translate(1 1)"><rect data-name="Rectangle 31" width="29.911" height="20.843" rx="2" /><path data-name="Line 91" d="M10.785 20.829v5.546" /><path data-name="Line 92" d="M19.14 20.829v5.546" /><path data-name="Line 93" d="M5.24 26.374h19.446" /></g></svg>,
  Calendar: () => <svg xmlns="http://www.w3.org/2000/svg" data-name="Iconly/Light/Calendar" viewBox="0 0 24 24"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M3.093 9.404h17.824M16.443 13.31h.009M12.005 13.31h.009M7.558 13.31h.009M16.443 17.196h.009M12.005 17.196h.009M7.558 17.196h.009M16.044 2v3.291M7.965 2v3.291M16.238 3.579H7.771C4.834 3.579 3 5.215 3 8.222v9.05C3 20.326 4.834 22 7.771 22h8.458C19.175 22 21 20.355 21 17.348V8.222c.009-3.007-1.816-4.643-4.762-4.643Z" /></g></svg>,
  Cellphone: () => <svg xmlns="http://www.w3.org/2000/svg" data-name="Iconly/Light/Calling" viewBox="0 0 24 24"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M2.99 4.874c.316-.525 2.06-2.43 3.303-2.374a1.642 1.642 0 0 1 .967.518h0a16.884 16.884 0 0 1 2.467 3.34C9.972 7.526 8.578 8.2 9 9.378a9.873 9.873 0 0 0 5.619 5.616c1.177.427 1.851-.965 3.019-.723a16.864 16.864 0 0 1 3.34 2.468h0a1.639 1.639 0 0 1 .517.967c.046 1.309-1.977 3.077-2.371 3.3-.93.666-2.144.654-3.624-.034C11.374 19.257 4.774 12.782 3.024 8.5c-.67-1.475-.715-2.7-.034-3.626Z" data-name="Stroke 1" /><path d="M14.565 2.75a7.971 7.971 0 0 1 7.041 7.032" /><path d="M14.565 6.293a4.426 4.426 0 0 1 3.5 3.5" data-name="Path" /></g></svg>,
  GPS: () => <svg xmlns="http://www.w3.org/2000/svg" data-name="Iconly/Light/Location" viewBox="0 0 24 24"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(3.5 2)"><path d="M.739 8.391a7.6785375 7.6785375 0 1 1 15.357.048v.087a11.338 11.338 0 0 1-3.478 7.3 20.183 20.183 0 0 1-3.591 2.957.93.93 0 0 1-1.217 0 19.817 19.817 0 0 1-5.052-4.73A9.826 9.826 0 0 1 .739 8.417Z" /><circle cx="2.461" cy="2.461" r="2.461" transform="translate(5.957 6.078)" /></g></svg>,
  ID: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M20.75 15.35v1.4" data-name="Line 420" /><path d="M.75 16.75v-6a10 10 0 0 1 10-10h0a10 10 0 0 1 10 10v1.55" data-name="Path 319" /><path d="M5.75 15.07v-4.32a5 5 0 0 1 5-5h0a5 5 0 0 1 5 5v9" data-name="Path 320" /><path d="M5.75 19.75v-2.17" data-name="Line 421" /><path d="M10.75 9.75v5" data-name="Line 422" /><path d="M10.75 17.75v3" data-name="Line 423" /></g></svg>,
  Message: () => <svg xmlns="http://www.w3.org/2000/svg" data-name="Iconly/Light/Message" viewBox="0 0 24 24"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="m17.268 9.061-4.266 3.434a2.223 2.223 0 0 1-2.746 0L5.954 9.061" /><path d="M6.888 3.5h9.428A4.957 4.957 0 0 1 19.9 5.09a5.017 5.017 0 0 1 1.326 3.7v6.528a5.017 5.017 0 0 1-1.326 3.7 4.957 4.957 0 0 1-3.58 1.59H6.888C3.968 20.616 2 18.241 2 15.322V8.794C2 5.875 3.968 3.5 6.888 3.5Z" /></g></svg>,
  Users: () => <svg xmlns="http://www.w3.org/2000/svg" data-name="Iconly/Light/3 User" viewBox="0 0 24 24"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" data-name="3 User"><path d="M17.595 10.929a2.9 2.9 0 1 0 0-5.8" data-name="Stroke 1" /><path d="M18.929 14.085a9.435 9.435 0 0 1 1.423.206 2.337 2.337 0 0 1 1.712.978 1.381 1.381 0 0 1 0 1.184 2.361 2.361 0 0 1-1.712.984" data-name="Stroke 3" /><path d="M6.288 10.929a2.9 2.9 0 1 1 0-5.8" data-name="Stroke 5" /><path d="M4.956 14.085a9.435 9.435 0 0 0-1.423.206 2.334 2.334 0 0 0-1.711.978 1.375 1.375 0 0 0 0 1.184 2.358 2.358 0 0 0 1.711.984" data-name="Stroke 7" /><path d="M11.938 14.71c3.247 0 6.021.491 6.021 2.458s-2.756 2.475-6.021 2.475c-3.248 0-6.021-.492-6.021-2.458s2.756-2.475 6.021-2.475Z" data-name="Stroke 9" /><path d="M11.938 11.905a3.859 3.859 0 1 1 3.858-3.859 3.845 3.845 0 0 1-3.858 3.859Z" data-name="Stroke 11" /></g></svg>,
  Twitter: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="660 813.5 24 24"><g><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M680 821.5v.5c0 5.3-3.4 11.5-10.9 11.5-3 0-5.2-.7-7.1-2 .3.1.7.1 1 .1 1.9 0 3.7-.6 5.1-1.7-1.8 0-3.3-1.2-3.8-2.8.3.1.5.1.8.1.4 0 .8 0 1.1-.1-1.9-.3-3.1-1.9-3.1-3.9v-.1c.5.3 1.2.5 1.8.6-1.1-.8-1.8-2-1.8-3.4 0-.8.2-1.5.6-2 2 2.4 4.8 4 8.2 4.1-.1-.3-.1-.6-.1-.9 0-2.2 1.8-4 4.1-4 1.2 0 2.2.5 2.9 1.3.9-.2 1.8-.5 2.6-1-.3.9-1 1.7-1.8 2.2q1.3-.1 2.4-.6c-.5.8-1.2 1.5-2 2.1h0Z" /></g></svg>,
  Instagram: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="375 707.5 24 24"><defs><clipPath id="a"><path d="M375 707.5h24v24h-24z"/></clipPath></defs><g clipPath="url(#a)"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M390 728.5h-6c-3.3 0-6-2.7-6-6v-6c0-3.3 2.7-6 6-6h6c3.3 0 6 2.7 6 6v6c0 3.3-2.7 6-6 6Z" vectorEffect="non-scaling-stroke"/><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M391 719.5c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4Z" vectorEffect="non-scaling-stroke"/><path d="M393 714.4c0 .5-.4 1-1 1s-1-.5-1-1c0-.6.4-1 1-1s1 .4 1 1Z"/></g></svg>,
  LinkedIn: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="660 813.5 24 24"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path fill="none" d="M675 834.5h-6c-3.3 0-6-2.7-6-6v-6c0-3.3 2.7-6 6-6h6c3.3 0 6 2.7 6 6v6c0 3.3-2.7 6-6 6Z" vectorEffect="non-scaling-stroke"/><path d="M667.6 824.4v6" vectorEffect="non-scaling-stroke"/><path fill="none" d="M670.4 830.4v-3c0-1.7 1.3-3 3-3 1.6 0 3 1.3 3 3v3" vectorEffect="non-scaling-stroke"/><path d="M667.6 821.4v-.1" vectorEffect="non-scaling-stroke"/></g></svg>,
  Facebook: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="660 813.5 24 24"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path fill="none" d="M675 834.5h-6c-3.3 0-6-2.7-6-6v-6c0-3.3 2.7-6 6-6h6c3.3 0 6 2.7 6 6v6c0 3.3-2.7 6-6 6Z" vectorEffect="non-scaling-stroke"/><path fill="none" d="M672 834.4v-10c0-2.2 1.8-4 4-4" vectorEffect="non-scaling-stroke"/><path d="M669 826.5h6" vectorEffect="non-scaling-stroke"/></g></svg>,
  Share: () => <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      isolation: "isolate",
    }}
    viewBox="44.15 701.75 19.7 20.5"
  >
    <defs>
      <clipPath id="_clipPath_ueI47u6z2TnnYbuvx5rd4AVHxy6Uqig4">
        <rect x={44.15} y={701.75} width={19.7} height={20.5} />
      </clipPath>
    </defs>
    <g clipPath="url(#_clipPath_ueI47u6z2TnnYbuvx5rd4AVHxy6Uqig4)">
      <path
        d=" M 48.9 704.5 C 48.9 705.6 48 706.5 46.9 706.5 C 45.8 706.5 44.9 705.6 44.9 704.5 C 44.9 703.4 45.8 702.5 46.9 702.5 C 48 702.5 48.9 703.4 48.9 704.5 Z "
        fill="none"
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
      <path
        d=" M 62.9 719.5 C 62.9 720.6 62 721.5 60.9 721.5 C 59.8 721.5 58.9 720.6 58.9 719.5 C 58.9 718.4 59.8 717.5 60.9 717.5 C 62 717.5 62.9 718.4 62.9 719.5 Z "
        fill="none"
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
      <path
        d=" M 63.1 705.6 C 63.1 707 62 708.1 60.6 708.1 C 59.2 708.1 58.1 707 58.1 705.6 C 58.1 704.2 59.2 703.1 60.6 703.1 C 62 703.1 63.1 704.2 63.1 705.6 Z "
        fill="none"
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
      <path
        d=" M 55.9 714.5 C 55.9 716.7 54.1 718.5 51.9 718.5 C 49.7 718.5 47.9 716.7 47.9 714.5 C 47.9 712.3 49.7 710.5 51.9 710.5 C 54.1 710.5 55.9 712.3 55.9 714.5 Z "
        fill="none"
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
      <line
        x1={50.1}
        y1={710.9}
        x2={47.8}
        y2={706.3}
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
      <line
        x1={54.7}
        y1={711.7}
        x2={58.9}
        y2={707.5}
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
      <line
        x1={54.6}
        y1={717.4}
        x2={59.2}
        y2={718.5}
        vectorEffect="non-scaling-stroke"
        strokeWidth={1.5}
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeMiterlimit={4}
      />
    </g>
  </svg>
} as const;

export type IconNames = keyof typeof Icons;

interface IconProps {
  className?: string;
  viewBox?: string;
  style?: any;
  role?: string;
  size?: '16' | '24' | '32' | '40' | any;
  name: IconNames;
  color?: string;
}

const Icon: React.FC<IconProps> = ({ color = '#5128E2', name, size = '24', viewBox = "0 0 24 24", ...props }: IconProps) => {
  return (
    <svg width={size} height={size} stroke={color} fill={color} viewBox={viewBox} strokeWidth={1.5} {...props}>
      {Icons[name]()}
    </svg>
  );
};

Icon.defaultProps = {
  role: "img",
};

export default Icon;

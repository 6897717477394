import styled from 'styled-components';

interface ISection {
  bordered?: boolean;
}

export const Section = styled.section<ISection>`
  border-top: ${(props) =>
    props.bordered ? '1px solid rgba(108, 123, 138, 0.08)' : 'none'};
  padding: 1.563rem 0.625rem;
  position: relative;

  .mb {
    margin-bottom: 3.438rem;
  }

  .logo {
    align-items: center;
    border-radius: 100%;
    display: inline-flex;
    height: 12.5rem;
    justify-content: center;
    margin-right: 1.75rem;
    overflow: hidden;
    width: 12.5rem;
    img {
      height: 100%;
    }
  }

  .infos {
    h1 {
        color: var(--primary-color);
        font-size: 1.75rem;
        line-height: 2.188;
        margin-bottom: 0.313rem;
    }
  }
`;

import styled from "styled-components";

export const List = styled.ul`
    list-style: none;
    padding: 0;

    li {
        border-bottom: 1px solid rgba(108, 123, 138, .2);
        margin-bottom: .5rem;
        /* width: 80%; */

        p:first-child {
            font-weight: 700;
            color: var(--black);
            margin: .3em 0;
        }
    }
`;

export const TTWrapper = styled.div`
    /* width: 80%; */

    h2 {
        font-size: 1.25rem;
        font-weight: 700;
    }

    p.indicator {
        font-weight: 700;
        color: var(--black);
        margin: .3em 0;
        text-transform: uppercase;
    }
`
import styled from 'styled-components';

interface CommonProps {
  styled: string;
}

export const Wrapper = styled.div<CommonProps>`
  align-items: center;
  background-color: rgba(231, 240, 255, 1);
  border-radius: 0.5rem;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  height: ${props => props.styled === 'simple' ? '4.375rem' : '6.25rem'};

  .iconContainer {
    width: 50%;
    .icon {
      flex-grow: 1;
      color: #000;
      font-size: 2.25rem;
    }
  }
`;

export const BadgeContent = styled.div<CommonProps>`
  width: 100% ;
  flex-grow: 5;
  h3 {
    color: rgba(168, 147, 240, 1);
    font-size: 0.875rem;
  }
  p {
    text-align: ${props => props.styled === 'simple' ? 'center' : 'start'};
    margin: 0;
    color: #000;
    font-size: 1rem;
    strong {
      font-weight: 600;
    }
  }
`;

import styled from "styled-components";

interface IText {
  variant: 'title' | 'subtitle'
}

export const TextWrapper = styled.section<IText>`
${({variant}) => `text-transform: ${ variant === 'title' ? 'uppercase': 'initial'};`}
${({variant}) => `font-weight: ${ variant === 'title' ? 500: 400};`}
color: var(--primary-color);
font-size: 12px;
`;
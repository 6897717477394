import React from 'react';
import { Section } from './styles';
import { GetYear } from '../../helpers/Dates';

type ProfessionalExperienceProps = {
  experience: any;
};

export const ProfessionalExperience: React.FC<ProfessionalExperienceProps> = ({
  experience,
}: ProfessionalExperienceProps) => {
  return (
    <Section bordered={true}>
      <div className='d-flex align-items-center '>
        <div className='infos'>
          <h1>
            {experience.organization}
          </h1>
          <p className='mb-0'>
            {`Negócio: ${experience.branch} ${experience.standardProfessionalArea.name}`}
          </p>
        </div>
      </div>
        <hr />

      <div className='infos'>
        <h2>{experience.standardSpecialization.name}</h2>
        <p className='mb-0'>{experience.roleLevel}</p>
        <p className='mb-0'>{experience.city}</p>
        <p>
          {GetYear(experience.in)} -{' '}
          {experience.workHere ? (
            <span
              style={{
                fontWeight: 600,
                color: 'rgba(51, 226, 180, 1)',
              }}
            >
              Trabalhando Atualmente
            </span>
          ) : (
            GetYear(experience.until)
          )}
        </p>
        <p className='description mb-0'>{experience.description}</p>
      </div>
    </Section>
  );
};

import { Content } from "./styles";

export interface KnowledgeProps {
  organization?: string;
  cause?: string;
  description?: string;
  in: string;
  until: string;
}

export function Knowledge({ organization, cause, description, in: start, until: end  }: KnowledgeProps) {
  const startDate = new Date(start);
  const endDate = end ? new Date(end) : undefined;

  const formattedStartDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`;
  const formattedEndDate = endDate ? `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}` : 'Atualmente';

  return (
    <Content>
      <div className="header">
        <div className="circle"></div>
        <span><strong>{organization}</strong> {formattedStartDate} - {formattedEndDate}</span>
      </div>
      <div className="content">
        <section>
          <strong className="cause">{cause}</strong>
        </section>
        <span>{description}</span>
      </div>
    </Content>
  )
}
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Course } from '../../models/user';
import { CardAccordion, List } from './styles';

type LanguagesProps = {
  languages: Course[];
};

const Languages: React.FunctionComponent<LanguagesProps> = ({
  languages,
}: LanguagesProps) => {
  return (
    <Accordion defaultActiveKey='0'>
      <CardAccordion>
        {/* <Card.Header className='d-none d-xl-block'>
          <Accordion.Toggle as={Button} variant='link' eventKey='0'>
            Idiomas
          </Accordion.Toggle>
        </Card.Header> */}
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            {languages &&
              languages.map((language) => {
                return (
                  <List key={language._id}>
                    <p>
                      <strong>{language.name}</strong>
                    </p>
                    <p>{language.level}</p>
                  </List>
                );
              })}
          </Card.Body>
        </Accordion.Collapse>
      </CardAccordion>
    </Accordion>
  );
};

type SoftwaresProps = {
  softwares: Course[];
};

const Softwares: React.FunctionComponent<SoftwaresProps> = ({
  softwares,
}: SoftwaresProps) => {
  return (
    <Accordion defaultActiveKey='0'>
        <CardAccordion>
          {/* <Card.Header>
            <Accordion.Toggle as={Button} variant='link' eventKey='0'>
              Tecnologia
            </Accordion.Toggle>
          </Card.Header> */}
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              {softwares &&
                softwares.map((software: Course) => {
                  return (
                    <List key={software._id}>
                      <p>
                        <strong>{software.name}</strong>
                      </p>
                      <p>{software.level}</p>
                    </List>
                  );
                })}
            </Card.Body>
          </Accordion.Collapse>
        </CardAccordion>
      </Accordion>
  );
};

const LanguagesSoftwares: React.FunctionComponent = () => {
  return <Accordion defaultActiveKey='0'></Accordion>;
};
export { Languages, Softwares, LanguagesSoftwares };

import React, { useContext } from 'react';
import { format } from 'date-fns';
import { List, TTWrapper } from './styles';
import GetEnvironment from '../../helpers/GetEnvironment';
import { useParams, useSearchParams } from 'react-router-dom';
import CandidateContext from '../../contexts/Candidate';
import { secondsToMinutes } from '../../helpers/Time';
import { CompetenciesSantanderProfiles, GNS_Profiles, RCA_Profiles } from '../../helpers/Translator';

type Question = {
    active: string;
    answers: string;
    asking: string;
    questionId: string;
    type: string;

}

type SupplementaryQuestionsProps = {
    questions: Question[];
}

const SupplementaryQuestions: React.FunctionComponent<SupplementaryQuestionsProps> = ({ questions }: SupplementaryQuestionsProps) => {
    return (
        <List>
            {
                questions && questions.map((question) => {
                    return (
                        <li key={question.questionId}>
                            <p>{question.asking} <span>{question.active}</span></p>
                            <p>{question.answers}</p>
                        </li>
                    )
                }
                )
            }
        </List>
    );
};


type TypingTest = {
    "status": string;
    "stepId": string;
    "token": string;
    "active": boolean;
    "defaultText": string;
    "maxDuration": number;
    "date": string;
    "beginDate": string;
    "duration": number;
    "finishedDate": string;
    "text": string;
}

type TypingTestsProps = {
    tests: TypingTest[];
}

const TypingTests: React.FunctionComponent<TypingTestsProps> = ({ tests }: TypingTestsProps) => {
    return (
        <>
            {
                tests && tests.map((test, index) => {
                    return (
                        <TTWrapper key={test.stepId}>
                            <h2>
                                Teste #{index + 1}
                            </h2>
                            <p className='indicator'>Texto apresentado: </p>
                            <p>{test.defaultText}</p>
                            <p className='indicator'>Texto do candidato: </p>
                            <p>{test.text}</p>
                            <p className='indicator'>Teste realizado em: </p>
                            <p>{secondsToMinutes(test?.duration || 0)} de {secondsToMinutes(test?.maxDuration || 0)} disponível</p>
                        </TTWrapper>
                    )
                }
                )
            }
        </>
    );
};

type ConcentratedAttentionTestProps = {
    classification: string;
    educationLevel: string;
}

const ConcentratedAttentionTest: React.FunctionComponent<ConcentratedAttentionTestProps> = ({ classification, educationLevel }: ConcentratedAttentionTestProps) => {
    return (
        <>
            <p className='m-0'><span className='fw-bold text-dark'>Classificação:</span> {classification}</p>
            <p className='m-0'><span className='fw-bold text-dark'>Resultado para nível de educação:</span> {educationLevel}</p>
        </>
    );
};

type baseTest = {
  accessUrl: string;
  answers: any[];
  finishDate: string;
  progressPercentage: number;
  rightAnswersCount: number;
  stepId: string;
  test: {
    _id: string;
    name: string;
    resultsType?: string;
    customResultsType?: string;
    type: string;
    rule: string;
  };
  type: "not_objective_test" | "test_module";
};

type CustomTestType = baseTest & {
    beginDate?: string;
    isReplicaTest?: boolean;
    replicaDate?: string;
    status?: string;
    testScore?: number;
    token?: string;
    customResults?: Record<any, Record<any, any>>;
    results?: Record<any, Record<any, any>>;
};

type CustomTestProps = {
    tests: CustomTestType[]
}

const CustomTest: React.FunctionComponent<CustomTestProps> = ({ tests }: CustomTestProps) => {
    const { vacancyId } = useParams();
    const { candidate } = useContext(CandidateContext);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    return (
        <>
            {
                tests && tests.map(test => {
                    return handleSpecificTestLayout(test, candidate, token!, vacancyId!)
                })
            }
        </>
    );
};


const handleSpecificTestLayout = ({type, ...test}: CustomTestType, candidate: any, token: string, vacancyId: string) => {
    let layout = <>indefinido</>
    if (type ==='test_module') {
        layout = <section key={test.stepId}>
        <p className="mb-2 fw-bold text-primary-color text-uppercase text-attention-size">{test.test.name}</p>
        <p className='m-0 fw-bold text-dark'>Resultados</p>
        {!test.test.rule && <p className='m-0'>{test.testScore ? `${test.testScore * 100}%` : 'Sem correção'}</p>}
        {test.results && test.results.profiles && test.results.profiles.map((profile: any) => ( <p className='m-0'>{profile.name !== 'Sem atributo definido' ? `${profile.name}:` : ''} {profile.value} pontos </p>))}
        <p className='m-0 fw-bold text-dark'>Acessar o teste</p>
        <p className='m-0'>
            <a href={`${GetEnvironment().manager}/companies/tests/${candidate.vacancy?.company}/${vacancyId}/${candidate?.user?._id}/${test.stepId}/${test.test._id}/result?token=${token}`} target="_blank" rel="noreferrer">
                Acessar tela do teste
            </a>
        </p>
        <p className='m-0 fw-bold text-dark'>Iniciado em</p>
        <p className='m-0'>{test.beginDate ? format(new Date(test.beginDate), 'dd/MM/yyyy') : "Não iniciado"}</p>
        <p className='m-0 fw-bold text-dark'>Finalizado em</p>
        <p className='m-0'>{test.finishDate ? format(new Date(test.finishDate), 'dd/MM/yyyy') : "Em andamento"}</p>
        <hr/>
    </section>
   }

   if (type ==='not_objective_test') {
       if(test.results ) {
        layout = (
          <section key={test.stepId}>
            <p className="mb-2 fw-bold text-primary-color text-uppercase text-attention-size">
              {test.test.name}
            </p>
            <p className="m-0 fw-bold text-dark">Principal perfil</p>
            <p className="m-0">{GNS_Profiles[test.results!.profiles!.main]}</p>
            <p className="m-0 fw-bold text-dark">Pontuação em atividades gns</p>
            <p className="m-0">{test.results!.profiles!.points!.gns!.value}</p>
            <p className="m-0 fw-bold text-dark">
              Pontuação em atividades vendas
            </p>
            <p className="m-0">
              {test.results!.profiles!.points!.sales!.value}
            </p>
            <p className="m-0 fw-bold text-dark">
              Pontuação em atividades atendimento
            </p>
            <p className="m-0">
              {test.results!.profiles!.points!.attendance!.value}
            </p>
            <p className="m-0 fw-bold text-dark">
              Pontuação em atividades administrativo
            </p>
            <p className="m-0">
              {test.results!.profiles!.points!.administrative!.value}
            </p>

            <hr />
          </section>
        );

    }
    if (test.test.customResultsType === "profile_rca" ) {
        layout = (
            <section key={test.stepId}>
              <p className="mb-2 fw-bold text-primary-color text-uppercase text-attention-size">
                {test.test.name}
              </p>
              <p className="m-0 fw-bold text-dark">Principal perfil</p>
              <p className="m-0">{RCA_Profiles[test.customResults!.RCAprofiles!.main]}</p>
              <p className="m-0 fw-bold text-dark">Pontuação em empreendedor</p>
              <p className="m-0">{test.customResults!.RCAprofiles!.points!.entrepreneur}</p>
              <p className="m-0 fw-bold text-dark">
                Pontuação em embaixador
              </p>
              <p className="m-0">
                {test.customResults!.RCAprofiles!.points!.ambassador}
              </p>
              <p className="m-0 fw-bold text-dark">
                Pontuação em representante
              </p>
              <p className="m-0">
                {test.customResults!.RCAprofiles!.points!.representative}
              </p>
              <p className="m-0 fw-bold text-dark">
                Pontuação em não aderente
              </p>
              <p className="m-0">
                {test.customResults!.RCAprofiles!.points!.disqualified}
              </p>

              <hr />
            </section>
          );
    }
    if(test.test.customResultsType === "competencies_santander") {
        layout = (
          <section key={test.stepId}>
            <p className="mb-2 fw-bold text-primary-color text-uppercase text-attention-size">
              {test.test.name}
            </p>
            <p className="m-0 fw-bold text-dark">Principal perfil</p>
            <p className="m-0">{CompetenciesSantanderProfiles[test.customResults!.profiles!.main]}</p>
            <p className="m-0 fw-bold text-dark">Pontuação em atividades comerciais</p>
            <p className="m-0">{test.customResults!.profiles!.points!.commercial}</p>
            <p className="m-0 fw-bold text-dark">
              Pontuação em atividades de atendimento
            </p>
            <p className="m-0">
              {test.customResults!.profiles!.points!.attendance}
            </p>
            <p className="m-0 fw-bold text-dark">
              Pontuação em atividades de processo
            </p>
            <p className="m-0">
              {test.customResults!.profiles!.points!.proceduralActivity!}
            </p>
            <hr />
          </section>
        );

    }

    }

    return layout;
}

type VideoStepType = {

    "startTime": number,
    "endTime": number,
    "durationTime": number,
    "stepId": string,
    "videoURL": string,
    "oldVideoURL": string,
    "stepName": string,
    "videoInstructions": string[]

}

type VideoStepProps = {
    videos: VideoStepType[]
}

const VideoStep: React.FunctionComponent<VideoStepProps> = ({ videos }: VideoStepProps) => {
    return (
        <>
            {
                videos && videos.map(video => {
                    return (
                        <>
                            <p className='mb-2 fw-bold text-dark'>{video.stepName}</p>
                            <p className='mb-2 fw-bold text-dark'>Perguntas</p>
                            <ul>
                                {video.videoInstructions.map(instruction => (<li>{instruction}</li>))}
                            </ul>
                            {
                                video && video.videoURL && (
                                    <>
                                        <p className='mb-2 fw-bold text-dark'>Vídeo</p>
                                        <video src={video.videoURL}></video>
                                        <a href={video.videoURL} rel="noreferrer" target="_blank">{video.videoURL}</a>
                                    </>
                                )
                            }
                            {
                                video && !video.videoURL && (<span>Vídeo ainda não gravado.</span>)
                            }
                        </>
                    )
                })
            }
        </>
    );
};



type OnlineEssayType = {
    nota: number;
    sendDate?: string;
    status: string;
    theme: string;
    url: string;
}

type OnlineEssayProps = {
    essays: OnlineEssayType[]
}

const OnlineEssay: React.FunctionComponent<OnlineEssayProps> = ({ essays }: OnlineEssayProps) => {
    return (
        <>
            {
                essays && essays.map(essay => {
                    return (
                        <div key={essay.sendDate}>
                            <p className='m-0 fw-bold text-dark'>Tema da redação</p>
                            <p className='m-0'>{essay.theme}</p>
                            <p className='m-0 fw-bold text-dark'>Status</p>
                            <p className='m-0'>{essay.status}</p>
                            <p className='m-0 fw-bold text-dark'>Nota</p>
                            <p className='m-0'>{essay.nota}</p>
                            <p className='m-0 fw-bold text-dark'>Correção</p>
                            <p className='m-0 text-break'><a href={essay.url} target="_blank" rel="noopener noreferrer">{essay.url}</a></p>
                            <p className='m-0 fw-bold text-dark'>Redação enviada em</p>
                            <p className='m-0'>{essay.sendDate ? format(new Date(essay.sendDate), "dd/MM/yyyy 'às' HH:mm") : "Não enviada"}</p>
                            <hr />
                        </div>
                    )
                })
            }
        </>
    );
};

export {
    SupplementaryQuestions,
    TypingTests,
    ConcentratedAttentionTest,
    CustomTest,
    VideoStep,
    OnlineEssay,
};

import { Loader, Wrapper } from "./styles";


const Loading = () => {
    return (
        <Wrapper>
            <Loader>
                <div></div>
                <div></div>
            </Loader>
        </Wrapper>);
}

export default Loading;


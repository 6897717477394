import styled, { css } from "styled-components";

interface ContentCertificatesProps {
  noBorder?: boolean;
};

export const Container = styled.div`
    margin-left: 32px;
    grid-column: 2;
    padding: 10px;

    word-break: normal;
    hyphens: auto;
`;

export const ContentCertificates = styled.div<ContentCertificatesProps>`
  border-bottom: 1px solid #E5E5E8;

  ${({ noBorder }) => noBorder && css`
    border-bottom: none;
  `}

  .title{
    display: flex;
    align-items: center;
   
    gap: 0.5rem;
    font-weight: bold;
    margin-top: 1rem;
    color: var(--primary-color);

    h6 {
      margin-top: 1rem;
      text-transform: uppercase;
    }
  }

  
  .about-text {
    margin: 0.3rem 0;

    span {
      color: #68656E;
      font-size: 12px;
    }
  }

  .content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    padding: 20px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }

`;

import styled from 'styled-components';

interface IProps {
    paddingBottom: any;
}

export const Wrapper = styled.div<IProps>`
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom+'px' : '0'};
    .brand {
        img {
            height: 1.5rem;
            @media (min-width: 1020px) {
                height: 2.75rem;
            }
        }
    }
`;
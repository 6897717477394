import styled from "styled-components";

export const Container = styled.button`
    align-items: center;
    background-color: rgba(81,40,226,1);
    border: 1px solid rgba(81,40,226,1);
    border-radius: 0.25rem;
    color: #FFF;
    display: inline-flex;
    font-size: 0.875rem;
    justify-content: center;
    padding: 0.5rem 2rem;

    &:hover {
        background-color: #2f1390;
        border-color: #2f1390;
    }
`;
import styled from 'styled-components';

interface ISection {
  bordered?: boolean;
}

export const Content = styled.div<ISection>`
  ${(props) =>
    props.bordered &&
    'border-bottom: 1px solid rgba(108, 123, 138, 0.08); margin-bottom: 2.563rem;'}

  h1 {
    color: var(--primary-color);
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 3.188rem;
    margin-bottom: 0.813rem;
  }
  h2 {
    color: var(--black);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.563rem;
    margin-bottom: 2.188rem;
    span {
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.313rem;
    }
  }
  p {
    span {
      color: rgba(116, 83, 232, 0.5);
    }
  }
  .chart-box {
    flex: 1;
    /* margin-right: 5.188rem; */
    /* padding: */
    .recharts-surface {
      overflow: visible !important;
    }
  }
`;

export const Section = styled.div<ISection>`
  ${(props) =>
    props.bordered &&
    'border-bottom: 1px solid rgba(108, 123, 138, 0.08); margin-bottom: 2.563rem;'}
  h2 {
    color: var(--black);
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.563rem;
    margin-bottom: 0;
    span {
      color: var(--primary-color);
      font-weight: 600;
      margin-bottom: 0.313rem;
    }
  }
  p {
    span {
      color: rgba(116, 83, 232, 0.5);
    }
  }
  .chart-box {
    flex: 1;
    margin-right: 5.188rem;
    min-width: 5rem;
    max-width: 8.75rem !important;
    max-height: 8.75rem;
  }
  &.comp {
    padding-bottom: 2.563rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

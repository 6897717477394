import API from '../helpers/Axios'

const getUserData = ({ username, nameTag }: any) => {
    return API.get(`/public/professional-identity/${username}/${nameTag}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export {
    getUserData
}
import React from 'react';
import { Wrapper, BadgeContent } from './styles';

type BadgeProps = {
  icon?: JSX.Element;
  category?: string;
  title: string;
  description?: string;
};

const Badge: React.FC<BadgeProps> = ({ icon, category, title, description }: BadgeProps) => {
  const styledValue = icon && category && description ? 'default' : 'simple'
  return (
    <Wrapper styled={styledValue}>
      {icon && <div className='iconContainer d-flex align-items-center justify-content-center'
      >
        {icon}
      </div>}
      <BadgeContent styled={styledValue}
      >
        {category && <h3>{category}</h3>}
        <p className=''><strong>{title}</strong></p>
        {description && <p className='mb-0'>{description}</p>}
      </BadgeContent>
    </Wrapper>
  );
};

export default Badge;

import { KeenSliderOptions } from "keen-slider";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import { Arrow } from "../Arrow";
import './styles.scss';

interface SliderProps {
    children: any[];
    sliderConfig?: KeenSliderOptions,
    breakpointsConfig?: Record<any, unknown>
}

const Slider: React.FC<SliderProps> = ({ children, sliderConfig, breakpointsConfig }: SliderProps): JSX.Element => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [initialSlide, setInitialSlide] = useState<number>(0);
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {

            ...(sliderConfig ? sliderConfig : {
                ...(!breakpointsConfig ? {
                    breakpoints: {
                        '(min-width: 420px)': {
                            slides: { perView: 3, spacing: 10 },
                        },
                        '(min-width: 760px)': {
                            slides: { perView: 5, spacing: 10 },
                        },
                        '(min-width: 1020px)': {
                            slides: { perView: 3, spacing: 10 },
                        },
                        '(min-width: 1300px)': {
                            slides: { perView: 5, spacing: 10 },
                        },
                    },
                    slides: {
                        perView: 2,
                        spacing: 10
                    },
                } : breakpointsConfig),
                initial: 0,
                created(slider) {
                    const slideConfig = slider.options!.slides as Record<string, any>;
                    setCurrentSlide(slideConfig.perView - 1);
                },
                optionsChanged(slider) {
                    const slideConfig = slider.options!.slides as Record<string, any>;
                    setInitialSlide(slideConfig.perView - 1);
                },
                slideChanged(slider) {
                    const slideConfig = slider.options!.slides as Record<string, any>;
                    setCurrentSlide(slider.track.details.rel + (slideConfig.perView - 1));
                },
            })
        }

    );

    return (
        <>{instanceRef.current &&
            <div className="d-flex justify-content-end mt-3">
                <Arrow
                    left
                    onClick={(e: any): void => e.stopPropagation() || instanceRef.current?.prev()}
                    disabled={currentSlide === initialSlide}
                />
                <Arrow
                    onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                    disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                />
            </div>
        }
            <div className="navigation-wrapper">
                <div className='keen-slider' ref={sliderRef}>
                    {children}
                </div>
            </div>

        </>

    );
}

export default Slider;  
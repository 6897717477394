import React, { Fragment, useContext, useEffect } from 'react';
import Section from '../components/Section/index';
import { Candidate } from '../models/candidate';
import { TranslateVacancyActivities } from '../helpers/Translator';
import Badge from '../components/Badge';
import PageModal from '../components/PageModal';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import CandidateContext from '../contexts/Candidate';
import { getCandidateData } from '../services/candidate';
import DetailsNav from '../components/DetailsNav/DetailsNav';
import { ConcentratedAttentionTest, CustomTest, OnlineEssay, SupplementaryQuestions, TypingTests, VideoStep } from '../components/VacancysActivity';
import Slider from '../components/Slider';

type VacancyActivitiesLinksProps = {
  candidate: Candidate;
};

const baseVacancyActivities = [
  { key: 'supplementaryQuestions', type: 'array' },
  { key: 'onlineEssays', type: 'array' },
  { key: 'concentratedAttentionTest', type: 'object' },
  { key: 'uploadedVideosURLs', type: 'array' },
  { key: 'testResults', type: 'array' },
  { key: 'typingTest', type: 'array' },
];

const setActivitiesHelper = (candidate: Candidate) => {
  return baseVacancyActivities.map((activity: any) =>
    ({ key: activity.key, value: Array.isArray(candidate![activity.key]) ? candidate![activity.key].length ? candidate![activity.key] : null : candidate![activity.key] || null })
  ).filter(activity => activity.value);
}

export const VacancyActivitiesLinks: React.FC<
  VacancyActivitiesLinksProps
> = ({ candidate }: VacancyActivitiesLinksProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const sliderConfig = {
    breakpoints: {
      '(min-width: 420px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 760px)': {
        slides: { perView: 4, spacing: 10 },
      },
    },
    slides: {
      perView: 2,
      spacing: 10,
    },
  };

  const activities = setActivitiesHelper(candidate);
  return (
    <Fragment>
      <Section bordered={true}>
        <div
          className='d-flex flex-lg-row flex-column align-items-lg-center justify-content-between mb-4'
          id='atividades-da-vaga'
        >
          <h2 className='h6 mb-0'>Atividades da Vaga</h2>
          <p className='mb-0'>
            <small className="message-details">Clique nos cards para ver mais informações</small>
          </p>
        </div>



        {!!activities.length && <Slider breakpointsConfig={sliderConfig}>
          {
            activities.map((activity: any) => {
              return <Link to={`atividades-da-vaga?page=${activity.key}&token=${token}`} className='keen-slider__slide' key={activity.key}>
                <Badge title={TranslateVacancyActivities[activity.key]} />
              </Link>
            })
          }
        </Slider>
        }
        {!activities.length && <p>Candidato ainda não foi movimentado para atividades da vaga</p>}
      </Section>
    </Fragment>
  );
};

export const VacancyActivitiesPage: React.FC<any> = () => {
  const { candidate, setCandidate } = useContext(CandidateContext);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const page = searchParams.get('page');

  useEffect(() => {
    if (!candidate._id) {
      getCandidateData({ ...params, token }).then(value => {
        setCandidate(value.data)
      });
    }
  }, [params, token, candidate, setCandidate]);

  const activitiesHelper = setActivitiesHelper(candidate);
  const menuHelper = setActivitiesHelper(candidate).map(activity => ({ ...activity, title: TranslateVacancyActivities[activity.key] }));
  const activitiesPage = activitiesHelper.find(activity => activity.key === page);

  const mountContent = (page: string): any => {
    const dynamicContent: Record<string, any> = {
      supplementaryQuestions: <SupplementaryQuestions questions={activitiesPage?.value} />,
      typingTest: <TypingTests tests={activitiesPage?.value} />,
      concentratedAttentionTest: <ConcentratedAttentionTest {...activitiesPage?.value} />,
      testResults: <CustomTest tests={activitiesPage?.value} />,
      uploadedVideosURLs: <VideoStep videos={activitiesPage?.value} />,
      onlineEssays: <OnlineEssay essays={activitiesPage?.value} />,
    }

    return dynamicContent[page] || (<p>Tela ainda não implementada :/</p>);

  };

  return <PageModal nav={<DetailsNav navigation={menuHelper} title="Atividade da vaga" />} content={mountContent(page!)} badge={TranslateVacancyActivities[activitiesPage?.key]} />;
};


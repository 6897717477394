const GetEnvironment = () => {
    const envs: Record<any, any> = {
        'taqe-production': {
            api: 'https://api-gateway.taqe.com.br/api/v1',
            manager: 'https://gerenciador.taqe.com.br',
            ip: 'https://taqe.me'
        },
        'taqe-homolog': {
            api: 'https://api-gateway.taqe.mobi/api/v1',
            manager: 'https://gerenciador.taqe.mobi',
            ip: 'http://app-ip.taqe.mobi'
        }, 
        'taqe-qa': {
            api: 'https://api-gateway.qa-taqe.com/api/v1',
            manager: 'https://gerenciador.qa-taqe.com',
            ip: 'http://app-ip.qa-taqe.com'
        }, 
        'taqe-sandbox': {
            api: 'https://api-gateway.sandbox-taqe.com/api/v1',
            manager: 'https://gerenciador.sandbox-taqe.com',
            ip: 'http://app-ip.sandbox-taqe.com'
        },
        'local': {
            api: 'http://localhost:3001/api/v1',
            manager: 'http://localhost:4200',
            ip: 'http://localhost:3000'
        }
    }

    return envs[process.env.REACT_APP_STAGE || 'local'];
}

export default GetEnvironment;

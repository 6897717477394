export const GetAge = (date: string) => {
  const today = new Date();
  const birthdate = new Date(date);

  let age = today.getFullYear() - birthdate.getFullYear();
  const month = today.getMonth() - birthdate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
};

export const GetYear = (date: string) => {
  const dateParse = new Date(date);

  return dateParse.getFullYear().toString();
};

import { EduactionContent } from "./styles";

export interface EducationProps {
  name: string;
  formation: string;
  in: string;
  until: string;
}

export function Education({ name, formation, in: start, until: end }: EducationProps) {
  const startYear = new Date(start).getFullYear();
  const endYear = new Date(end).getFullYear();
  const currentYear = new Date().getFullYear();

  let displayYear;

  if (isNaN(endYear)) {
    displayYear = "Cursando";
  } else if (endYear === currentYear) {
    displayYear = "Cursando";
  } else {
    displayYear = endYear;
  }

  return(
    <EduactionContent>
      <div>
        <strong>{name}</strong>
        <li>{formation}
          <p>{`${startYear} - ${displayYear}`}</p>
        </li>
      </div>
    </EduactionContent>
  )
}

import styled from 'styled-components';

interface ISection {
  bordered?: boolean;
}

export const Section = styled.section<ISection>`
  border-bottom: ${(props) =>
    props.bordered ? '1px solid rgba(108, 123, 138, 0.08)' : 'none'};
  padding: 0 0.625rem 1.563rem 0.625rem;
  position: relative;

  .mb {
    margin-bottom: 3.438rem;
  }

  .logo {
    margin-right: 1.75rem;
    img {
      max-width: 12.5rem;
    }
  }

  .infos {
    h1, h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
 
    h1 {
      /* line-height: 2.188; */
      margin-bottom: 0.313rem;
      /* span { */
        color: var(--primary-color);
      /* } */
    }
  }
`;

import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;

  li {
    border-bottom: 1px solid rgba(108, 123, 138, .2);
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    p:first-child {
      font-weight: 700;
      color: var(--black);
      margin: .3em 0;
    }

    a {
      text-decoration: underline;
    }
  }
`;

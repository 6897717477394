import styled from "styled-components";

export const ContactContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-top: 1rem;

    position: relative;

    span {
      font-size: 0.76rem;
    }
  }
`;

export const IconContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  gap: 0.5rem;

  img {
    width: 16px;
    height: 16px;
    color: white !important;
    fill: #FFF; /* Defina a cor desejada */
  }
`;

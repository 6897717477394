import styled from 'styled-components';

interface IDotChartIsIcon {
  setIcon: boolean;
}

export const DotChart = styled.div<IDotChartIsIcon>`
  span {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    background: ${props => props.setIcon && props.setIcon === true ? 'var(--white)' : '#F0F0F0' };
    display: block;
    position: relative;
    float: left;
    margin: 0 4px;
    &.full {
        background: var(--primary-color);
    }
  }
`;

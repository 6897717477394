import React from 'react';
import Card from '../../../components/Card';
import { setPersonality } from '../../../components/Stamps';

import { Link, useSearchParams } from 'react-router-dom';
import { TranslateStamps } from '../../../helpers/Translator';
import { ChartRadar, DotsChart, ChartPieIcon, avarageHelper } from '../../../components/Charts';


type StampsCardsProps = {
  data: any;
  isCandidate?: Boolean;
};

const baseSkills = [
  { key: 'english', type: 'array' },
  { key: 'generalKnowledge', type: 'array' },
  { key: 'logic', type: 'array' },
  { key: 'logicAdvanced', type: 'array' },
  { key: 'portuguese', type: 'array' },
  { key: 'competencies', type: 'array' }
];

export const setSkillsHelper = (data: any) => {
  return baseSkills
    .map((skill: any) => ({
      key: skill.key,
      value: Array.isArray(data![skill.key])
        ? data![skill.key].length
          ? data![skill.key]
          : null
        : data![skill.key] || null,
    }))
    .filter((skill) => skill.key);
};

export const PersonalityLink: React.FC<StampsCardsProps> = ({
  data,
  isCandidate = true
}: StampsCardsProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  return (
    data.value && (
      <Link
        to={`selos?page=${data.key}${isCandidate ? `&token=${token}` : ''}`}
        className='keen-slider__slide'
        key={data.key}
      >
        <Card
          title={TranslateStamps[data.key]}
          subtitle={data.value.personalityType.title}
          description={data.value.personalityType.subtitle}
          icon={
            <ChartRadar
              data={setPersonality(data.value.indicators)}
              isIcon={true}
            />
          }
        />
      </Link>
    )
  );
};

export const EnglishLink: React.FC<StampsCardsProps> = ({
  data,
  isCandidate = true
}: StampsCardsProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const avarage = avarageHelper(data.value);
  return (
    data.value && (
      <Link
        to={`selos?page=${data.key}${isCandidate ? `&token=${token}` : ''}`}
        className='keen-slider__slide'
      >
        <Card
          title={TranslateStamps[data.key]}
          subtitle={'Média dos testes'}
          description={`${avarage}% de acertos`}
          icon={<ChartPieIcon data={data.value} isIcon={true} />}
        />
      </Link>
    )
  );
};

export const GeneralKnowledgeLink: React.FC<StampsCardsProps> = ({
  data,
  isCandidate = true
}: StampsCardsProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const avarage = avarageHelper(data.value);
  return (
    data.value && (
      <Link
        to={`selos?page=${data.key}${isCandidate ? `&token=${token}` : ''}`}
        className='keen-slider__slide'
      >
        <Card
          title={TranslateStamps[data.key]}
          subtitle={'Média dos testes'}
          description={`${avarage}% de acertos`}
          icon={<ChartPieIcon data={data.value} isIcon={true} />}
        />
      </Link>
    )
  );
};

export const LogicLink: React.FC<StampsCardsProps> = ({
  data,
  isCandidate = true
}: StampsCardsProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const avarage = avarageHelper(data.value);
  return (
    data.value && (
      <Link
        to={`selos?page=${data.key}${isCandidate ? `&token=${token}` : ''}`}
        className='keen-slider__slide'
      >
        <Card
          title={TranslateStamps[data.key]}
          subtitle={'Média dos testes'}
          description={`${avarage.toFixed(1)}% de acertos`}
          icon={<ChartPieIcon data={data.value} isIcon={true} />}
        />
      </Link>
    )
  );
};

export const PortugueseLink: React.FC<StampsCardsProps> = ({
  data,
  isCandidate = true
}: StampsCardsProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const avarage = avarageHelper(data.value);
  return (
    data.value && (
      <Link
        to={`selos?page=${data.key}${isCandidate ? `&token=${token}` : ''}`}
        className='keen-slider__slide'
      >
        <Card
          title={TranslateStamps[data.key]}
          subtitle={'Média dos testes'}
          description={`${avarage}% de acertos`}
          icon={<ChartPieIcon data={data.value} isIcon={true} />}
        />
      </Link>
    )
  );
};

export const CompetenciesLink: React.FC<StampsCardsProps> = ({ data, isCandidate = true }: StampsCardsProps) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  
  return (
    data.value && (
      <Link
        to={`selos?page=${data.key}${isCandidate ? `&token=${token}` : ''}`}
        className='keen-slider__slide'
      >
        <Card
          title={TranslateStamps[data.key]}
          description={'Confira os níveis das competências da TAQE'}
          icon={<DotsChart data={10} isIcon={true} />}
        />
      </Link>
    )
  );
};